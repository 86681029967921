@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Baloo+2");
.animationRight {
  animation: moveInRight 1s ease-out; }

.animationLeft {
  animation: moveInLeft 1s ease-out; }

.animationTop {
  animation: moveInTop 1s ease-out; }

@keyframes moveInLeft {
  0% {
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    transform: translate(0); } }

@keyframes moveInTop {
  0% {
    transform: translateY(-1.2rem); }
  100% {
    transform: translate(0); } }

@keyframes moveInTopLowDistance {
  0% {
    transform: translateY(-2rem); }
  100% {
    transform: translate(0); } }

@keyframes moveUpTop {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-0.5rem); } }

@keyframes moveUpHover {
  100% {
    transform: translateY(-1.2rem); } }

@keyframes moveUpTop2 {
  100% {
    transform: translateY(-1.2rem); } }

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  font-family: system-ui; }

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  /* font-size: 16px; */
  line-height: 1.7; }

.loading-spinner__overlay {
  height: 20rem;
  width: 100%;
  position: fixed;
  top: 9rem;
  left: 0;
  right: 0;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  z-index: 2000000; }

.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px; }

.modal__header {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #0b4353;
  color: white; }

.modal__header h2 {
  margin: 0.5rem; }

.modal__content {
  padding: 1rem 0.5rem; }

.modal__footer {
  padding: 1rem 0.5rem; }

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem; } }

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0; }

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms; }

.modal-exit {
  transform: translateY(0);
  opacity: 1; }

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms; }

.modalButton {
  color: white;
  text-align: center;
  background: #0b4353;
  width: 5rem;
  height: 2rem;
  border-radius: 1rem;
  border: none; }

.confirmation {
  width: 30rem;
  height: 20rem;
  position: fixed;
  margin: auto;
  margin-top: 10rem; }
  .confirmation-title {
    height: 6rem;
    background-color: #007000;
    color: white;
    padding: 2rem; }
  .confirmation-yesButton {
    background-color: #0a0a94;
    color: white;
    border: none;
    border-radius: 2rem;
    height: 4rem;
    width: 5rem; }
  .confirmation-noButton {
    background-color: #0a0a94;
    color: white;
    border: none;
    border-radius: 2rem;
    height: 4rem;
    width: 5rem; }

.marginleft {
  margin-left: 2rem; }

.blockHeight {
  position: relative; }

.atag {
  color: black; }

.margin2 {
  margin: 2rem; }

.inlineBlock {
  display: inline-block; }

.block {
  display: block; }

.verticalAlign {
  vertical-align: top; }

.carouselDisplayNone {
  display: none; }

.carouselDisplayBlock {
  display: block; }

.italicFont {
  font-style: italic; }

.textAlignCenter {
  text-align: center; }

.getFont {
  font-family: "Palatino, URW Palladio L, serif"; }

.animationRight {
  animation: moveInRight 1s ease-out; }

.animationLeft {
  animation: moveInLeft 1s ease-out; }

.animationTop {
  animation: moveInTop 1s ease-out; }

.fontSizeChange {
  font-size: 4rem; }

@media (max-width: 768px) {
  .marginleft {
    margin-left: 0rem; }
  .fontSizeChange {
    font-size: 2.9rem; } }

@media (min-width: 2512px) {
  .marginleft {
    margin-left: 0rem; }
  .fontSizeChange {
    font-size: 2.9rem; } }

.use-bootstrap {
  /*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
  /*# sourceMappingURL=bootstrap.min.css.map */ }
  .use-bootstrap :root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0)
    );
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff; }
  .use-bootstrap *,
  .use-bootstrap ::after,
  .use-bootstrap ::before {
    box-sizing: border-box; }
  @media (prefers-reduced-motion: no-preference) {
    .use-bootstrap :root {
      scroll-behavior: smooth; } }
  .use-bootstrap body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent; }
  .use-bootstrap hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25; }
  .use-bootstrap hr:not([size]) {
    height: 1px; }
  .use-bootstrap .h1,
  .use-bootstrap .h2,
  .use-bootstrap .h3,
  .use-bootstrap .h4,
  .use-bootstrap .h5,
  .use-bootstrap .h6,
  .use-bootstrap h1,
  .use-bootstrap h2,
  .use-bootstrap h3,
  .use-bootstrap h4,
  .use-bootstrap h5,
  .use-bootstrap h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2; }
  .use-bootstrap .h1,
  .use-bootstrap h1 {
    font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    .use-bootstrap .h1,
    .use-bootstrap h1 {
      font-size: 2.5rem; } }
  .use-bootstrap .h2,
  .use-bootstrap h2 {
    font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    .use-bootstrap .h2,
    .use-bootstrap h2 {
      font-size: 2rem; } }
  .use-bootstrap .h3,
  .use-bootstrap h3 {
    font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    .use-bootstrap .h3,
    .use-bootstrap h3 {
      font-size: 1.75rem; } }
  .use-bootstrap .h4,
  .use-bootstrap h4 {
    font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    .use-bootstrap .h4,
    .use-bootstrap h4 {
      font-size: 1.5rem; } }
  .use-bootstrap .h5,
  .use-bootstrap h5 {
    font-size: 1.25rem; }
  .use-bootstrap .h6,
  .use-bootstrap h6 {
    font-size: 1rem; }
  .use-bootstrap p {
    margin-top: 0;
    margin-bottom: 1rem; }
  .use-bootstrap abbr[data-bs-original-title],
  .use-bootstrap abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none; }
  .use-bootstrap address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }
  .use-bootstrap ol,
  .use-bootstrap ul {
    padding-left: 2rem; }
  .use-bootstrap dl,
  .use-bootstrap ol,
  .use-bootstrap ul {
    margin-top: 0;
    margin-bottom: 1rem; }
  .use-bootstrap ol ol,
  .use-bootstrap ol ul,
  .use-bootstrap ul ol,
  .use-bootstrap ul ul {
    margin-bottom: 0; }
  .use-bootstrap dt {
    font-weight: 700; }
  .use-bootstrap dd {
    margin-bottom: 0.5rem;
    margin-left: 0; }
  .use-bootstrap blockquote {
    margin: 0 0 1rem; }
  .use-bootstrap b,
  .use-bootstrap strong {
    font-weight: bolder; }
  .use-bootstrap .small,
  .use-bootstrap small {
    font-size: 0.875em; }
  .use-bootstrap .mark,
  .use-bootstrap mark {
    padding: 0.2em;
    background-color: #fcf8e3; }
  .use-bootstrap sub,
  .use-bootstrap sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline; }
  .use-bootstrap sub {
    bottom: -0.25em; }
  .use-bootstrap sup {
    top: -0.5em; }
  .use-bootstrap a {
    color: #0d6efd;
    text-decoration: underline; }
  .use-bootstrap a:hover {
    color: #0a58ca; }
  .use-bootstrap a:not([href]):not([class]),
  .use-bootstrap a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }
  .use-bootstrap code,
  .use-bootstrap kbd,
  .use-bootstrap pre,
  .use-bootstrap samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override; }
  .use-bootstrap pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em; }
  .use-bootstrap pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }
  .use-bootstrap code {
    font-size: 0.875em;
    color: #d63384;
    word-wrap: break-word; }
  .use-bootstrap a > code {
    color: inherit; }
  .use-bootstrap kbd {
    padding: 0.2rem 0.4rem;
    font-size: 0.875em;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem; }
  .use-bootstrap kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }
  .use-bootstrap figure {
    margin: 0 0 1rem; }
  .use-bootstrap img,
  .use-bootstrap svg {
    vertical-align: middle; }
  .use-bootstrap table {
    caption-side: bottom;
    border-collapse: collapse; }
  .use-bootstrap caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: left; }
  .use-bootstrap th {
    text-align: inherit;
    text-align: -webkit-match-parent; }
  .use-bootstrap tbody,
  .use-bootstrap td,
  .use-bootstrap tfoot,
  .use-bootstrap th,
  .use-bootstrap thead,
  .use-bootstrap tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0; }
  .use-bootstrap label {
    display: inline-block; }
  .use-bootstrap button {
    border-radius: 0; }
  .use-bootstrap button:focus:not(:focus-visible) {
    outline: 0; }
  .use-bootstrap button,
  .use-bootstrap input,
  .use-bootstrap optgroup,
  .use-bootstrap select,
  .use-bootstrap textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  .use-bootstrap button,
  .use-bootstrap select {
    text-transform: none; }
  .use-bootstrap [role="button"] {
    cursor: pointer; }
  .use-bootstrap select {
    word-wrap: normal; }
  .use-bootstrap select:disabled {
    opacity: 1; }
  .use-bootstrap [list]::-webkit-calendar-picker-indicator {
    display: none; }
  .use-bootstrap [type="button"],
  .use-bootstrap [type="reset"],
  .use-bootstrap [type="submit"],
  .use-bootstrap button {
    -webkit-appearance: button; }
  .use-bootstrap [type="button"]:not(:disabled),
  .use-bootstrap [type="reset"]:not(:disabled),
  .use-bootstrap [type="submit"]:not(:disabled),
  .use-bootstrap button:not(:disabled) {
    cursor: pointer; }
  .use-bootstrap ::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  .use-bootstrap textarea {
    resize: vertical; }
  .use-bootstrap fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  .use-bootstrap legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit; }
  @media (min-width: 1200px) {
    .use-bootstrap legend {
      font-size: 1.5rem; } }
  .use-bootstrap legend + * {
    clear: left; }
  .use-bootstrap ::-webkit-datetime-edit-day-field,
  .use-bootstrap ::-webkit-datetime-edit-fields-wrapper,
  .use-bootstrap ::-webkit-datetime-edit-hour-field,
  .use-bootstrap ::-webkit-datetime-edit-minute,
  .use-bootstrap ::-webkit-datetime-edit-month-field,
  .use-bootstrap ::-webkit-datetime-edit-text,
  .use-bootstrap ::-webkit-datetime-edit-year-field {
    padding: 0; }
  .use-bootstrap ::-webkit-inner-spin-button {
    height: auto; }
  .use-bootstrap [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield; }
  .use-bootstrap ::-webkit-search-decoration {
    -webkit-appearance: none; }
  .use-bootstrap ::-webkit-color-swatch-wrapper {
    padding: 0; }
  .use-bootstrap ::-webkit-file-upload-button {
    font: inherit; }
  .use-bootstrap ::file-selector-button {
    font: inherit; }
  .use-bootstrap ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  .use-bootstrap output {
    display: inline-block; }
  .use-bootstrap iframe {
    border: 0; }
  .use-bootstrap summary {
    display: list-item;
    cursor: pointer; }
  .use-bootstrap progress {
    vertical-align: baseline; }
  .use-bootstrap [hidden] {
    display: none !important; }
  .use-bootstrap .lead {
    font-size: 1.25rem;
    font-weight: 300; }
  .use-bootstrap .display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2; }
  @media (min-width: 1200px) {
    .use-bootstrap .display-1 {
      font-size: 5rem; } }
  .use-bootstrap .display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2; }
  @media (min-width: 1200px) {
    .use-bootstrap .display-2 {
      font-size: 4.5rem; } }
  .use-bootstrap .display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2; }
  @media (min-width: 1200px) {
    .use-bootstrap .display-3 {
      font-size: 4rem; } }
  .use-bootstrap .display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2; }
  @media (min-width: 1200px) {
    .use-bootstrap .display-4 {
      font-size: 3.5rem; } }
  .use-bootstrap .display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2; }
  @media (min-width: 1200px) {
    .use-bootstrap .display-5 {
      font-size: 3rem; } }
  .use-bootstrap .display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2; }
  @media (min-width: 1200px) {
    .use-bootstrap .display-6 {
      font-size: 2.5rem; } }
  .use-bootstrap .list-unstyled {
    padding-left: 0;
    list-style: none; }
  .use-bootstrap .list-inline {
    padding-left: 0;
    list-style: none; }
  .use-bootstrap .list-inline-item {
    display: inline-block; }
  .use-bootstrap .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }
  .use-bootstrap .initialism {
    font-size: 0.875em;
    text-transform: uppercase; }
  .use-bootstrap .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem; }
  .use-bootstrap .blockquote > :last-child {
    margin-bottom: 0; }
  .use-bootstrap .blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 0.875em;
    color: #6c757d; }
  .use-bootstrap .blockquote-footer::before {
    content: "— "; }
  .use-bootstrap .img-fluid {
    max-width: 100%;
    height: auto; }
  .use-bootstrap .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto; }
  .use-bootstrap .figure {
    display: inline-block; }
  .use-bootstrap .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1; }
  .use-bootstrap .figure-caption {
    font-size: 0.875em;
    color: #6c757d; }
  .use-bootstrap .container,
  .use-bootstrap .container-fluid,
  .use-bootstrap .container-lg,
  .use-bootstrap .container-md,
  .use-bootstrap .container-sm,
  .use-bootstrap .container-xl,
  .use-bootstrap .container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 576px) {
    .use-bootstrap .container,
    .use-bootstrap .container-sm {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .use-bootstrap .container,
    .use-bootstrap .container-md,
    .use-bootstrap .container-sm {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .use-bootstrap .container,
    .use-bootstrap .container-lg,
    .use-bootstrap .container-md,
    .use-bootstrap .container-sm {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .use-bootstrap .container,
    .use-bootstrap .container-lg,
    .use-bootstrap .container-md,
    .use-bootstrap .container-sm,
    .use-bootstrap .container-xl {
      max-width: 1140px; } }
  @media (min-width: 1400px) {
    .use-bootstrap .container,
    .use-bootstrap .container-lg,
    .use-bootstrap .container-md,
    .use-bootstrap .container-sm,
    .use-bootstrap .container-xl,
    .use-bootstrap .container-xxl {
      max-width: 1320px; } }
  .use-bootstrap .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x)); }
  .use-bootstrap .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y); }
  .use-bootstrap .col {
    flex: 1 0 0%; }
  .use-bootstrap .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .use-bootstrap .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .use-bootstrap .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .use-bootstrap .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .use-bootstrap .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .use-bootstrap .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .use-bootstrap .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }
  .use-bootstrap .col-auto {
    flex: 0 0 auto;
    width: auto; }
  .use-bootstrap .col-1 {
    flex: 0 0 auto;
    width: 8.33333333%; }
  .use-bootstrap .col-2 {
    flex: 0 0 auto;
    width: 16.66666667%; }
  .use-bootstrap .col-3 {
    flex: 0 0 auto;
    width: 25%; }
  .use-bootstrap .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%; }
  .use-bootstrap .col-5 {
    flex: 0 0 auto;
    width: 41.66666667%; }
  .use-bootstrap .col-6 {
    flex: 0 0 auto;
    width: 50%; }
  .use-bootstrap .col-7 {
    flex: 0 0 auto;
    width: 58.33333333%; }
  .use-bootstrap .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%; }
  .use-bootstrap .col-9 {
    flex: 0 0 auto;
    width: 75%; }
  .use-bootstrap .col-10 {
    flex: 0 0 auto;
    width: 83.33333333%; }
  .use-bootstrap .col-11 {
    flex: 0 0 auto;
    width: 91.66666667%; }
  .use-bootstrap .col-12 {
    flex: 0 0 auto;
    width: 100%; }
  .use-bootstrap .offset-1 {
    margin-left: 8.33333333%; }
  .use-bootstrap .offset-2 {
    margin-left: 16.66666667%; }
  .use-bootstrap .offset-3 {
    margin-left: 25%; }
  .use-bootstrap .offset-4 {
    margin-left: 33.33333333%; }
  .use-bootstrap .offset-5 {
    margin-left: 41.66666667%; }
  .use-bootstrap .offset-6 {
    margin-left: 50%; }
  .use-bootstrap .offset-7 {
    margin-left: 58.33333333%; }
  .use-bootstrap .offset-8 {
    margin-left: 66.66666667%; }
  .use-bootstrap .offset-9 {
    margin-left: 75%; }
  .use-bootstrap .offset-10 {
    margin-left: 83.33333333%; }
  .use-bootstrap .offset-11 {
    margin-left: 91.66666667%; }
  .use-bootstrap .g-0,
  .use-bootstrap .gx-0 {
    --bs-gutter-x: 0; }
  .use-bootstrap .g-0,
  .use-bootstrap .gy-0 {
    --bs-gutter-y: 0; }
  .use-bootstrap .g-1,
  .use-bootstrap .gx-1 {
    --bs-gutter-x: 0.25rem; }
  .use-bootstrap .g-1,
  .use-bootstrap .gy-1 {
    --bs-gutter-y: 0.25rem; }
  .use-bootstrap .g-2,
  .use-bootstrap .gx-2 {
    --bs-gutter-x: 0.5rem; }
  .use-bootstrap .g-2,
  .use-bootstrap .gy-2 {
    --bs-gutter-y: 0.5rem; }
  .use-bootstrap .g-3,
  .use-bootstrap .gx-3 {
    --bs-gutter-x: 1rem; }
  .use-bootstrap .g-3,
  .use-bootstrap .gy-3 {
    --bs-gutter-y: 1rem; }
  .use-bootstrap .g-4,
  .use-bootstrap .gx-4 {
    --bs-gutter-x: 1.5rem; }
  .use-bootstrap .g-4,
  .use-bootstrap .gy-4 {
    --bs-gutter-y: 1.5rem; }
  .use-bootstrap .g-5,
  .use-bootstrap .gx-5 {
    --bs-gutter-x: 3rem; }
  .use-bootstrap .g-5,
  .use-bootstrap .gy-5 {
    --bs-gutter-y: 3rem; }
  @media (min-width: 576px) {
    .use-bootstrap .col-sm {
      flex: 1 0 0%; }
    .use-bootstrap .row-cols-sm-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .use-bootstrap .row-cols-sm-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .use-bootstrap .row-cols-sm-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .use-bootstrap .row-cols-sm-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%; }
    .use-bootstrap .row-cols-sm-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .use-bootstrap .row-cols-sm-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .use-bootstrap .row-cols-sm-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%; }
    .use-bootstrap .col-sm-auto {
      flex: 0 0 auto;
      width: auto; }
    .use-bootstrap .col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333333%; }
    .use-bootstrap .col-sm-2 {
      flex: 0 0 auto;
      width: 16.66666667%; }
    .use-bootstrap .col-sm-3 {
      flex: 0 0 auto;
      width: 25%; }
    .use-bootstrap .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333333%; }
    .use-bootstrap .col-sm-5 {
      flex: 0 0 auto;
      width: 41.66666667%; }
    .use-bootstrap .col-sm-6 {
      flex: 0 0 auto;
      width: 50%; }
    .use-bootstrap .col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333333%; }
    .use-bootstrap .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66666667%; }
    .use-bootstrap .col-sm-9 {
      flex: 0 0 auto;
      width: 75%; }
    .use-bootstrap .col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333333%; }
    .use-bootstrap .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%; }
    .use-bootstrap .col-sm-12 {
      flex: 0 0 auto;
      width: 100%; }
    .use-bootstrap .offset-sm-0 {
      margin-left: 0; }
    .use-bootstrap .offset-sm-1 {
      margin-left: 8.33333333%; }
    .use-bootstrap .offset-sm-2 {
      margin-left: 16.66666667%; }
    .use-bootstrap .offset-sm-3 {
      margin-left: 25%; }
    .use-bootstrap .offset-sm-4 {
      margin-left: 33.33333333%; }
    .use-bootstrap .offset-sm-5 {
      margin-left: 41.66666667%; }
    .use-bootstrap .offset-sm-6 {
      margin-left: 50%; }
    .use-bootstrap .offset-sm-7 {
      margin-left: 58.33333333%; }
    .use-bootstrap .offset-sm-8 {
      margin-left: 66.66666667%; }
    .use-bootstrap .offset-sm-9 {
      margin-left: 75%; }
    .use-bootstrap .offset-sm-10 {
      margin-left: 83.33333333%; }
    .use-bootstrap .offset-sm-11 {
      margin-left: 91.66666667%; }
    .use-bootstrap .g-sm-0,
    .use-bootstrap .gx-sm-0 {
      --bs-gutter-x: 0; }
    .use-bootstrap .g-sm-0,
    .use-bootstrap .gy-sm-0 {
      --bs-gutter-y: 0; }
    .use-bootstrap .g-sm-1,
    .use-bootstrap .gx-sm-1 {
      --bs-gutter-x: 0.25rem; }
    .use-bootstrap .g-sm-1,
    .use-bootstrap .gy-sm-1 {
      --bs-gutter-y: 0.25rem; }
    .use-bootstrap .g-sm-2,
    .use-bootstrap .gx-sm-2 {
      --bs-gutter-x: 0.5rem; }
    .use-bootstrap .g-sm-2,
    .use-bootstrap .gy-sm-2 {
      --bs-gutter-y: 0.5rem; }
    .use-bootstrap .g-sm-3,
    .use-bootstrap .gx-sm-3 {
      --bs-gutter-x: 1rem; }
    .use-bootstrap .g-sm-3,
    .use-bootstrap .gy-sm-3 {
      --bs-gutter-y: 1rem; }
    .use-bootstrap .g-sm-4,
    .use-bootstrap .gx-sm-4 {
      --bs-gutter-x: 1.5rem; }
    .use-bootstrap .g-sm-4,
    .use-bootstrap .gy-sm-4 {
      --bs-gutter-y: 1.5rem; }
    .use-bootstrap .g-sm-5,
    .use-bootstrap .gx-sm-5 {
      --bs-gutter-x: 3rem; }
    .use-bootstrap .g-sm-5,
    .use-bootstrap .gy-sm-5 {
      --bs-gutter-y: 3rem; } }
  @media (min-width: 768px) {
    .use-bootstrap .col-md {
      flex: 1 0 0%; }
    .use-bootstrap .row-cols-md-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .use-bootstrap .row-cols-md-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .use-bootstrap .row-cols-md-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .use-bootstrap .row-cols-md-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%; }
    .use-bootstrap .row-cols-md-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .use-bootstrap .row-cols-md-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .use-bootstrap .row-cols-md-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%; }
    .use-bootstrap .col-md-auto {
      flex: 0 0 auto;
      width: auto; }
    .use-bootstrap .col-md-1 {
      flex: 0 0 auto;
      width: 8.33333333%; }
    .use-bootstrap .col-md-2 {
      flex: 0 0 auto;
      width: 16.66666667%; }
    .use-bootstrap .col-md-3 {
      flex: 0 0 auto;
      width: 25%; }
    .use-bootstrap .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%; }
    .use-bootstrap .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%; }
    .use-bootstrap .col-md-6 {
      flex: 0 0 auto;
      width: 50%; }
    .use-bootstrap .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%; }
    .use-bootstrap .col-md-8 {
      flex: 0 0 auto;
      width: 66.66666667%; }
    .use-bootstrap .col-md-9 {
      flex: 0 0 auto;
      width: 75%; }
    .use-bootstrap .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%; }
    .use-bootstrap .col-md-11 {
      flex: 0 0 auto;
      width: 91.66666667%; }
    .use-bootstrap .col-md-12 {
      flex: 0 0 auto;
      width: 100%; }
    .use-bootstrap .offset-md-0 {
      margin-left: 0; }
    .use-bootstrap .offset-md-1 {
      margin-left: 8.33333333%; }
    .use-bootstrap .offset-md-2 {
      margin-left: 16.66666667%; }
    .use-bootstrap .offset-md-3 {
      margin-left: 25%; }
    .use-bootstrap .offset-md-4 {
      margin-left: 33.33333333%; }
    .use-bootstrap .offset-md-5 {
      margin-left: 41.66666667%; }
    .use-bootstrap .offset-md-6 {
      margin-left: 50%; }
    .use-bootstrap .offset-md-7 {
      margin-left: 58.33333333%; }
    .use-bootstrap .offset-md-8 {
      margin-left: 66.66666667%; }
    .use-bootstrap .offset-md-9 {
      margin-left: 75%; }
    .use-bootstrap .offset-md-10 {
      margin-left: 83.33333333%; }
    .use-bootstrap .offset-md-11 {
      margin-left: 91.66666667%; }
    .use-bootstrap .g-md-0,
    .use-bootstrap .gx-md-0 {
      --bs-gutter-x: 0; }
    .use-bootstrap .g-md-0,
    .use-bootstrap .gy-md-0 {
      --bs-gutter-y: 0; }
    .use-bootstrap .g-md-1,
    .use-bootstrap .gx-md-1 {
      --bs-gutter-x: 0.25rem; }
    .use-bootstrap .g-md-1,
    .use-bootstrap .gy-md-1 {
      --bs-gutter-y: 0.25rem; }
    .use-bootstrap .g-md-2,
    .use-bootstrap .gx-md-2 {
      --bs-gutter-x: 0.5rem; }
    .use-bootstrap .g-md-2,
    .use-bootstrap .gy-md-2 {
      --bs-gutter-y: 0.5rem; }
    .use-bootstrap .g-md-3,
    .use-bootstrap .gx-md-3 {
      --bs-gutter-x: 1rem; }
    .use-bootstrap .g-md-3,
    .use-bootstrap .gy-md-3 {
      --bs-gutter-y: 1rem; }
    .use-bootstrap .g-md-4,
    .use-bootstrap .gx-md-4 {
      --bs-gutter-x: 1.5rem; }
    .use-bootstrap .g-md-4,
    .use-bootstrap .gy-md-4 {
      --bs-gutter-y: 1.5rem; }
    .use-bootstrap .g-md-5,
    .use-bootstrap .gx-md-5 {
      --bs-gutter-x: 3rem; }
    .use-bootstrap .g-md-5,
    .use-bootstrap .gy-md-5 {
      --bs-gutter-y: 3rem; } }
  @media (min-width: 992px) {
    .use-bootstrap .col-lg {
      flex: 1 0 0%; }
    .use-bootstrap .row-cols-lg-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .use-bootstrap .row-cols-lg-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .use-bootstrap .row-cols-lg-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .use-bootstrap .row-cols-lg-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%; }
    .use-bootstrap .row-cols-lg-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .use-bootstrap .row-cols-lg-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .use-bootstrap .row-cols-lg-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%; }
    .use-bootstrap .col-lg-auto {
      flex: 0 0 auto;
      width: auto; }
    .use-bootstrap .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%; }
    .use-bootstrap .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%; }
    .use-bootstrap .col-lg-3 {
      flex: 0 0 auto;
      width: 25%; }
    .use-bootstrap .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%; }
    .use-bootstrap .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%; }
    .use-bootstrap .col-lg-6 {
      flex: 0 0 auto;
      width: 50%; }
    .use-bootstrap .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%; }
    .use-bootstrap .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%; }
    .use-bootstrap .col-lg-9 {
      flex: 0 0 auto;
      width: 75%; }
    .use-bootstrap .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%; }
    .use-bootstrap .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%; }
    .use-bootstrap .col-lg-12 {
      flex: 0 0 auto;
      width: 100%; }
    .use-bootstrap .offset-lg-0 {
      margin-left: 0; }
    .use-bootstrap .offset-lg-1 {
      margin-left: 8.33333333%; }
    .use-bootstrap .offset-lg-2 {
      margin-left: 16.66666667%; }
    .use-bootstrap .offset-lg-3 {
      margin-left: 25%; }
    .use-bootstrap .offset-lg-4 {
      margin-left: 33.33333333%; }
    .use-bootstrap .offset-lg-5 {
      margin-left: 41.66666667%; }
    .use-bootstrap .offset-lg-6 {
      margin-left: 50%; }
    .use-bootstrap .offset-lg-7 {
      margin-left: 58.33333333%; }
    .use-bootstrap .offset-lg-8 {
      margin-left: 66.66666667%; }
    .use-bootstrap .offset-lg-9 {
      margin-left: 75%; }
    .use-bootstrap .offset-lg-10 {
      margin-left: 83.33333333%; }
    .use-bootstrap .offset-lg-11 {
      margin-left: 91.66666667%; }
    .use-bootstrap .g-lg-0,
    .use-bootstrap .gx-lg-0 {
      --bs-gutter-x: 0; }
    .use-bootstrap .g-lg-0,
    .use-bootstrap .gy-lg-0 {
      --bs-gutter-y: 0; }
    .use-bootstrap .g-lg-1,
    .use-bootstrap .gx-lg-1 {
      --bs-gutter-x: 0.25rem; }
    .use-bootstrap .g-lg-1,
    .use-bootstrap .gy-lg-1 {
      --bs-gutter-y: 0.25rem; }
    .use-bootstrap .g-lg-2,
    .use-bootstrap .gx-lg-2 {
      --bs-gutter-x: 0.5rem; }
    .use-bootstrap .g-lg-2,
    .use-bootstrap .gy-lg-2 {
      --bs-gutter-y: 0.5rem; }
    .use-bootstrap .g-lg-3,
    .use-bootstrap .gx-lg-3 {
      --bs-gutter-x: 1rem; }
    .use-bootstrap .g-lg-3,
    .use-bootstrap .gy-lg-3 {
      --bs-gutter-y: 1rem; }
    .use-bootstrap .g-lg-4,
    .use-bootstrap .gx-lg-4 {
      --bs-gutter-x: 1.5rem; }
    .use-bootstrap .g-lg-4,
    .use-bootstrap .gy-lg-4 {
      --bs-gutter-y: 1.5rem; }
    .use-bootstrap .g-lg-5,
    .use-bootstrap .gx-lg-5 {
      --bs-gutter-x: 3rem; }
    .use-bootstrap .g-lg-5,
    .use-bootstrap .gy-lg-5 {
      --bs-gutter-y: 3rem; } }
  @media (min-width: 1200px) {
    .use-bootstrap .col-xl {
      flex: 1 0 0%; }
    .use-bootstrap .row-cols-xl-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .use-bootstrap .row-cols-xl-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .use-bootstrap .row-cols-xl-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .use-bootstrap .row-cols-xl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%; }
    .use-bootstrap .row-cols-xl-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .use-bootstrap .row-cols-xl-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .use-bootstrap .row-cols-xl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%; }
    .use-bootstrap .col-xl-auto {
      flex: 0 0 auto;
      width: auto; }
    .use-bootstrap .col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333333%; }
    .use-bootstrap .col-xl-2 {
      flex: 0 0 auto;
      width: 16.66666667%; }
    .use-bootstrap .col-xl-3 {
      flex: 0 0 auto;
      width: 25%; }
    .use-bootstrap .col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333333%; }
    .use-bootstrap .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%; }
    .use-bootstrap .col-xl-6 {
      flex: 0 0 auto;
      width: 50%; }
    .use-bootstrap .col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333333%; }
    .use-bootstrap .col-xl-8 {
      flex: 0 0 auto;
      width: 66.66666667%; }
    .use-bootstrap .col-xl-9 {
      flex: 0 0 auto;
      width: 75%; }
    .use-bootstrap .col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333333%; }
    .use-bootstrap .col-xl-11 {
      flex: 0 0 auto;
      width: 91.66666667%; }
    .use-bootstrap .col-xl-12 {
      flex: 0 0 auto;
      width: 100%; }
    .use-bootstrap .offset-xl-0 {
      margin-left: 0; }
    .use-bootstrap .offset-xl-1 {
      margin-left: 8.33333333%; }
    .use-bootstrap .offset-xl-2 {
      margin-left: 16.66666667%; }
    .use-bootstrap .offset-xl-3 {
      margin-left: 25%; }
    .use-bootstrap .offset-xl-4 {
      margin-left: 33.33333333%; }
    .use-bootstrap .offset-xl-5 {
      margin-left: 41.66666667%; }
    .use-bootstrap .offset-xl-6 {
      margin-left: 50%; }
    .use-bootstrap .offset-xl-7 {
      margin-left: 58.33333333%; }
    .use-bootstrap .offset-xl-8 {
      margin-left: 66.66666667%; }
    .use-bootstrap .offset-xl-9 {
      margin-left: 75%; }
    .use-bootstrap .offset-xl-10 {
      margin-left: 83.33333333%; }
    .use-bootstrap .offset-xl-11 {
      margin-left: 91.66666667%; }
    .use-bootstrap .g-xl-0,
    .use-bootstrap .gx-xl-0 {
      --bs-gutter-x: 0; }
    .use-bootstrap .g-xl-0,
    .use-bootstrap .gy-xl-0 {
      --bs-gutter-y: 0; }
    .use-bootstrap .g-xl-1,
    .use-bootstrap .gx-xl-1 {
      --bs-gutter-x: 0.25rem; }
    .use-bootstrap .g-xl-1,
    .use-bootstrap .gy-xl-1 {
      --bs-gutter-y: 0.25rem; }
    .use-bootstrap .g-xl-2,
    .use-bootstrap .gx-xl-2 {
      --bs-gutter-x: 0.5rem; }
    .use-bootstrap .g-xl-2,
    .use-bootstrap .gy-xl-2 {
      --bs-gutter-y: 0.5rem; }
    .use-bootstrap .g-xl-3,
    .use-bootstrap .gx-xl-3 {
      --bs-gutter-x: 1rem; }
    .use-bootstrap .g-xl-3,
    .use-bootstrap .gy-xl-3 {
      --bs-gutter-y: 1rem; }
    .use-bootstrap .g-xl-4,
    .use-bootstrap .gx-xl-4 {
      --bs-gutter-x: 1.5rem; }
    .use-bootstrap .g-xl-4,
    .use-bootstrap .gy-xl-4 {
      --bs-gutter-y: 1.5rem; }
    .use-bootstrap .g-xl-5,
    .use-bootstrap .gx-xl-5 {
      --bs-gutter-x: 3rem; }
    .use-bootstrap .g-xl-5,
    .use-bootstrap .gy-xl-5 {
      --bs-gutter-y: 3rem; } }
  @media (min-width: 1400px) {
    .use-bootstrap .col-xxl {
      flex: 1 0 0%; }
    .use-bootstrap .row-cols-xxl-auto > * {
      flex: 0 0 auto;
      width: auto; }
    .use-bootstrap .row-cols-xxl-1 > * {
      flex: 0 0 auto;
      width: 100%; }
    .use-bootstrap .row-cols-xxl-2 > * {
      flex: 0 0 auto;
      width: 50%; }
    .use-bootstrap .row-cols-xxl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%; }
    .use-bootstrap .row-cols-xxl-4 > * {
      flex: 0 0 auto;
      width: 25%; }
    .use-bootstrap .row-cols-xxl-5 > * {
      flex: 0 0 auto;
      width: 20%; }
    .use-bootstrap .row-cols-xxl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%; }
    .use-bootstrap .col-xxl-auto {
      flex: 0 0 auto;
      width: auto; }
    .use-bootstrap .col-xxl-1 {
      flex: 0 0 auto;
      width: 8.33333333%; }
    .use-bootstrap .col-xxl-2 {
      flex: 0 0 auto;
      width: 16.66666667%; }
    .use-bootstrap .col-xxl-3 {
      flex: 0 0 auto;
      width: 25%; }
    .use-bootstrap .col-xxl-4 {
      flex: 0 0 auto;
      width: 33.33333333%; }
    .use-bootstrap .col-xxl-5 {
      flex: 0 0 auto;
      width: 41.66666667%; }
    .use-bootstrap .col-xxl-6 {
      flex: 0 0 auto;
      width: 50%; }
    .use-bootstrap .col-xxl-7 {
      flex: 0 0 auto;
      width: 58.33333333%; }
    .use-bootstrap .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.66666667%; }
    .use-bootstrap .col-xxl-9 {
      flex: 0 0 auto;
      width: 75%; }
    .use-bootstrap .col-xxl-10 {
      flex: 0 0 auto;
      width: 83.33333333%; }
    .use-bootstrap .col-xxl-11 {
      flex: 0 0 auto;
      width: 91.66666667%; }
    .use-bootstrap .col-xxl-12 {
      flex: 0 0 auto;
      width: 100%; }
    .use-bootstrap .offset-xxl-0 {
      margin-left: 0; }
    .use-bootstrap .offset-xxl-1 {
      margin-left: 8.33333333%; }
    .use-bootstrap .offset-xxl-2 {
      margin-left: 16.66666667%; }
    .use-bootstrap .offset-xxl-3 {
      margin-left: 25%; }
    .use-bootstrap .offset-xxl-4 {
      margin-left: 33.33333333%; }
    .use-bootstrap .offset-xxl-5 {
      margin-left: 41.66666667%; }
    .use-bootstrap .offset-xxl-6 {
      margin-left: 50%; }
    .use-bootstrap .offset-xxl-7 {
      margin-left: 58.33333333%; }
    .use-bootstrap .offset-xxl-8 {
      margin-left: 66.66666667%; }
    .use-bootstrap .offset-xxl-9 {
      margin-left: 75%; }
    .use-bootstrap .offset-xxl-10 {
      margin-left: 83.33333333%; }
    .use-bootstrap .offset-xxl-11 {
      margin-left: 91.66666667%; }
    .use-bootstrap .g-xxl-0,
    .use-bootstrap .gx-xxl-0 {
      --bs-gutter-x: 0; }
    .use-bootstrap .g-xxl-0,
    .use-bootstrap .gy-xxl-0 {
      --bs-gutter-y: 0; }
    .use-bootstrap .g-xxl-1,
    .use-bootstrap .gx-xxl-1 {
      --bs-gutter-x: 0.25rem; }
    .use-bootstrap .g-xxl-1,
    .use-bootstrap .gy-xxl-1 {
      --bs-gutter-y: 0.25rem; }
    .use-bootstrap .g-xxl-2,
    .use-bootstrap .gx-xxl-2 {
      --bs-gutter-x: 0.5rem; }
    .use-bootstrap .g-xxl-2,
    .use-bootstrap .gy-xxl-2 {
      --bs-gutter-y: 0.5rem; }
    .use-bootstrap .g-xxl-3,
    .use-bootstrap .gx-xxl-3 {
      --bs-gutter-x: 1rem; }
    .use-bootstrap .g-xxl-3,
    .use-bootstrap .gy-xxl-3 {
      --bs-gutter-y: 1rem; }
    .use-bootstrap .g-xxl-4,
    .use-bootstrap .gx-xxl-4 {
      --bs-gutter-x: 1.5rem; }
    .use-bootstrap .g-xxl-4,
    .use-bootstrap .gy-xxl-4 {
      --bs-gutter-y: 1.5rem; }
    .use-bootstrap .g-xxl-5,
    .use-bootstrap .gx-xxl-5 {
      --bs-gutter-x: 3rem; }
    .use-bootstrap .g-xxl-5,
    .use-bootstrap .gy-xxl-5 {
      --bs-gutter-y: 3rem; } }
  .use-bootstrap .table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #212529;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #212529;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #212529;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6; }
  .use-bootstrap .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .use-bootstrap .table > tbody {
    vertical-align: inherit; }
  .use-bootstrap .table > thead {
    vertical-align: bottom; }
  .use-bootstrap .table > :not(:first-child) {
    border-top: 2px solid currentColor; }
  .use-bootstrap .caption-top {
    caption-side: top; }
  .use-bootstrap .table-sm > :not(caption) > * > * {
    padding: 0.25rem 0.25rem; }
  .use-bootstrap .table-bordered > :not(caption) > * {
    border-width: 1px 0; }
  .use-bootstrap .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }
  .use-bootstrap .table-borderless > :not(caption) > * > * {
    border-bottom-width: 0; }
  .use-bootstrap .table-borderless > :not(:first-child) {
    border-top-width: 0; }
  .use-bootstrap .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color); }
  .use-bootstrap .table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color); }
  .use-bootstrap .table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color); }
  .use-bootstrap .table-primary {
    --bs-table-bg: #cfe2ff;
    --bs-table-striped-bg: #c5d7f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bacbe6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfd1ec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bacbe6; }
  .use-bootstrap .table-secondary {
    --bs-table-bg: #e2e3e5;
    --bs-table-striped-bg: #d7d8da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cbccce;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d1d2d4;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #cbccce; }
  .use-bootstrap .table-success {
    --bs-table-bg: #d1e7dd;
    --bs-table-striped-bg: #c7dbd2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bcd0c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c1d6cc;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bcd0c7; }
  .use-bootstrap .table-info {
    --bs-table-bg: #cff4fc;
    --bs-table-striped-bg: #c5e8ef;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #badce3;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfe2e9;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #badce3; }
  .use-bootstrap .table-warning {
    --bs-table-bg: #fff3cd;
    --bs-table-striped-bg: #f2e7c3;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dbb9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece1be;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6dbb9; }
  .use-bootstrap .table-danger {
    --bs-table-bg: #f8d7da;
    --bs-table-striped-bg: #eccccf;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfc2c4;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5c7ca;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfc2c4; }
  .use-bootstrap .table-light {
    --bs-table-bg: #f8f9fa;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #dfe0e1; }
  .use-bootstrap .table-dark {
    --bs-table-bg: #212529;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #fff;
    border-color: #373b3e; }
  .use-bootstrap .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
  @media (max-width: 575.98px) {
    .use-bootstrap .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  @media (max-width: 767.98px) {
    .use-bootstrap .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  @media (max-width: 991.98px) {
    .use-bootstrap .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  @media (max-width: 1199.98px) {
    .use-bootstrap .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  @media (max-width: 1399.98px) {
    .use-bootstrap .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; } }
  .use-bootstrap .form-label {
    margin-bottom: 0.5rem; }
  .use-bootstrap .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }
  .use-bootstrap .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem; }
  .use-bootstrap .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem; }
  .use-bootstrap .form-text {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #6c757d; }
  .use-bootstrap .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .form-control {
      transition: none; } }
  .use-bootstrap .form-control[type="file"] {
    overflow: hidden; }
  .use-bootstrap .form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .use-bootstrap .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .use-bootstrap .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .use-bootstrap .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .use-bootstrap .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .use-bootstrap .form-control:disabled,
  .use-bootstrap .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .use-bootstrap .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .use-bootstrap .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none; }
    .use-bootstrap .form-control::file-selector-button {
      transition: none; } }
  .use-bootstrap .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }
  .use-bootstrap .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .use-bootstrap .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .form-control::-webkit-file-upload-button {
      -webkit-transition: none;
      transition: none; } }
  .use-bootstrap .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }
  .use-bootstrap .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0; }
  .use-bootstrap .form-control-plaintext.form-control-lg,
  .use-bootstrap .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0; }
  .use-bootstrap .form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  .use-bootstrap .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem; }
  .use-bootstrap .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem; }
  .use-bootstrap .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem; }
  .use-bootstrap .form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  .use-bootstrap .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem; }
  .use-bootstrap .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem; }
  .use-bootstrap .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end: 1rem; }
  .use-bootstrap textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px); }
  .use-bootstrap textarea.form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px); }
  .use-bootstrap textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px); }
  .use-bootstrap .form-control-color {
    width: 3rem;
    height: auto;
    padding: 0.375rem; }
  .use-bootstrap .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .use-bootstrap .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }
  .use-bootstrap .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }
  .use-bootstrap .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .form-select {
      transition: none; } }
  .use-bootstrap .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .use-bootstrap .form-select[multiple],
  .use-bootstrap .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .use-bootstrap .form-select:disabled {
    background-color: #e9ecef; }
  .use-bootstrap .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529; }
  .use-bootstrap .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  .use-bootstrap .form-select-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  .use-bootstrap .form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem; }
  .use-bootstrap .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }
  .use-bootstrap .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact; }
  .use-bootstrap .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .use-bootstrap .form-check-input[type="radio"] {
    border-radius: 50%; }
  .use-bootstrap .form-check-input:active {
    filter: brightness(90%); }
  .use-bootstrap .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .use-bootstrap .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .use-bootstrap .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
  .use-bootstrap .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .use-bootstrap .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .use-bootstrap .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .use-bootstrap .form-check-input:disabled ~ .form-check-label,
  .use-bootstrap .form-check-input[disabled] ~ .form-check-label {
    opacity: 0.5; }
  .use-bootstrap .form-switch {
    padding-left: 2.5em; }
  .use-bootstrap .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .form-switch .form-check-input {
      transition: none; } }
  .use-bootstrap .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); }
  .use-bootstrap .form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .use-bootstrap .form-check-inline {
    display: inline-block;
    margin-right: 1rem; }
  .use-bootstrap .btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }
  .use-bootstrap .btn-check:disabled + .btn,
  .use-bootstrap .btn-check[disabled] + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }
  .use-bootstrap .form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .use-bootstrap .form-range:focus {
    outline: 0; }
  .use-bootstrap .form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .use-bootstrap .form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .use-bootstrap .form-range::-moz-focus-outer {
    border: 0; }
  .use-bootstrap .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .form-range::-webkit-slider-thumb {
      -webkit-transition: none;
      transition: none; } }
  .use-bootstrap .form-range::-webkit-slider-thumb:active {
    background-color: #b6d4fe; }
  .use-bootstrap .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .use-bootstrap .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
    appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .form-range::-moz-range-thumb {
      -moz-transition: none;
      transition: none; } }
  .use-bootstrap .form-range::-moz-range-thumb:active {
    background-color: #b6d4fe; }
  .use-bootstrap .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .use-bootstrap .form-range:disabled {
    pointer-events: none; }
  .use-bootstrap .form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .use-bootstrap .form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .use-bootstrap .form-floating {
    position: relative; }
  .use-bootstrap .form-floating > .form-control,
  .use-bootstrap .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .use-bootstrap .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .form-floating > label {
      transition: none; } }
  .use-bootstrap .form-floating > .form-control {
    padding: 1rem 0.75rem; }
  .use-bootstrap .form-floating > .form-control::-moz-placeholder {
    color: transparent; }
  .use-bootstrap .form-floating > .form-control::placeholder {
    color: transparent; }
  .use-bootstrap .form-floating > .form-control:not(:-moz-placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .use-bootstrap .form-floating > .form-control:focus,
  .use-bootstrap .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .use-bootstrap .form-floating > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .use-bootstrap .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .use-bootstrap .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .use-bootstrap .form-floating > .form-control:focus ~ label,
  .use-bootstrap .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .use-bootstrap .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .use-bootstrap .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .use-bootstrap .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%; }
  .use-bootstrap .input-group > .form-control,
  .use-bootstrap .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .use-bootstrap .input-group > .form-control:focus,
  .use-bootstrap .input-group > .form-select:focus {
    z-index: 3; }
  .use-bootstrap .input-group .btn {
    position: relative;
    z-index: 2; }
  .use-bootstrap .input-group .btn:focus {
    z-index: 3; }
  .use-bootstrap .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem; }
  .use-bootstrap .input-group-lg > .btn,
  .use-bootstrap .input-group-lg > .form-control,
  .use-bootstrap .input-group-lg > .form-select,
  .use-bootstrap .input-group-lg > .input-group-text {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  .use-bootstrap .input-group-sm > .btn,
  .use-bootstrap .input-group-sm > .form-control,
  .use-bootstrap .input-group-sm > .form-select,
  .use-bootstrap .input-group-sm > .input-group-text {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  .use-bootstrap .input-group-lg > .form-select,
  .use-bootstrap .input-group-sm > .form-select {
    padding-right: 3rem; }
  .use-bootstrap .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .use-bootstrap .input-group:not(.has-validation)
> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .use-bootstrap .input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
  .use-bootstrap .input-group.has-validation
> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .use-bootstrap .input-group
> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .use-bootstrap .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #198754; }
  .use-bootstrap .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(25, 135, 84, 0.9);
    border-radius: 0.25rem; }
  .use-bootstrap .is-valid ~ .valid-feedback,
  .use-bootstrap .is-valid ~ .valid-tooltip,
  .use-bootstrap .was-validated :valid ~ .valid-feedback,
  .use-bootstrap .was-validated :valid ~ .valid-tooltip {
    display: block; }
  .use-bootstrap .form-control.is-valid,
  .use-bootstrap .was-validated .form-control:valid {
    border-color: #198754;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .use-bootstrap .form-control.is-valid:focus,
  .use-bootstrap .was-validated .form-control:valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }
  .use-bootstrap .was-validated textarea.form-control:valid,
  .use-bootstrap textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  .use-bootstrap .form-select.is-valid,
  .use-bootstrap .was-validated .form-select:valid {
    border-color: #198754; }
  .use-bootstrap .form-select.is-valid:not([multiple]):not([size]),
  .use-bootstrap .form-select.is-valid:not([multiple])[size="1"],
  .use-bootstrap .was-validated .form-select:valid:not([multiple]):not([size]),
  .use-bootstrap .was-validated .form-select:valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .use-bootstrap .form-select.is-valid:focus,
  .use-bootstrap .was-validated .form-select:valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }
  .use-bootstrap .form-check-input.is-valid,
  .use-bootstrap .was-validated .form-check-input:valid {
    border-color: #198754; }
  .use-bootstrap .form-check-input.is-valid:checked,
  .use-bootstrap .was-validated .form-check-input:valid:checked {
    background-color: #198754; }
  .use-bootstrap .form-check-input.is-valid:focus,
  .use-bootstrap .was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }
  .use-bootstrap .form-check-input.is-valid ~ .form-check-label,
  .use-bootstrap .was-validated .form-check-input:valid ~ .form-check-label {
    color: #198754; }
  .use-bootstrap .form-check-inline .form-check-input ~ .valid-feedback {
    margin-left: 0.5em; }
  .use-bootstrap .input-group .form-control.is-valid,
  .use-bootstrap .input-group .form-select.is-valid,
  .use-bootstrap .was-validated .input-group .form-control:valid,
  .use-bootstrap .was-validated .input-group .form-select:valid {
    z-index: 1; }
  .use-bootstrap .input-group .form-control.is-valid:focus,
  .use-bootstrap .input-group .form-select.is-valid:focus,
  .use-bootstrap .was-validated .input-group .form-control:valid:focus,
  .use-bootstrap .was-validated .input-group .form-select:valid:focus {
    z-index: 3; }
  .use-bootstrap .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545; }
  .use-bootstrap .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem; }
  .use-bootstrap .is-invalid ~ .invalid-feedback,
  .use-bootstrap .is-invalid ~ .invalid-tooltip,
  .use-bootstrap .was-validated :invalid ~ .invalid-feedback,
  .use-bootstrap .was-validated :invalid ~ .invalid-tooltip {
    display: block; }
  .use-bootstrap .form-control.is-invalid,
  .use-bootstrap .was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .use-bootstrap .form-control.is-invalid:focus,
  .use-bootstrap .was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .use-bootstrap .was-validated textarea.form-control:invalid,
  .use-bootstrap textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }
  .use-bootstrap .form-select.is-invalid,
  .use-bootstrap .was-validated .form-select:invalid {
    border-color: #dc3545; }
  .use-bootstrap .form-select.is-invalid:not([multiple]):not([size]),
  .use-bootstrap .form-select.is-invalid:not([multiple])[size="1"],
  .use-bootstrap .was-validated .form-select:invalid:not([multiple]):not([size]),
  .use-bootstrap .was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .use-bootstrap .form-select.is-invalid:focus,
  .use-bootstrap .was-validated .form-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .use-bootstrap .form-check-input.is-invalid,
  .use-bootstrap .was-validated .form-check-input:invalid {
    border-color: #dc3545; }
  .use-bootstrap .form-check-input.is-invalid:checked,
  .use-bootstrap .was-validated .form-check-input:invalid:checked {
    background-color: #dc3545; }
  .use-bootstrap .form-check-input.is-invalid:focus,
  .use-bootstrap .was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .use-bootstrap .form-check-input.is-invalid ~ .form-check-label,
  .use-bootstrap .was-validated .form-check-input:invalid ~ .form-check-label {
    color: #dc3545; }
  .use-bootstrap .form-check-inline .form-check-input ~ .invalid-feedback {
    margin-left: 0.5em; }
  .use-bootstrap .input-group .form-control.is-invalid,
  .use-bootstrap .input-group .form-select.is-invalid,
  .use-bootstrap .was-validated .input-group .form-control:invalid,
  .use-bootstrap .was-validated .input-group .form-select:invalid {
    z-index: 2; }
  .use-bootstrap .input-group .form-control.is-invalid:focus,
  .use-bootstrap .input-group .form-select.is-invalid:focus,
  .use-bootstrap .was-validated .input-group .form-control:invalid:focus,
  .use-bootstrap .was-validated .input-group .form-select:invalid:focus {
    z-index: 3; }
  .use-bootstrap .btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .btn {
      transition: none; } }
  .use-bootstrap .btn:hover {
    color: #212529; }
  .use-bootstrap .btn-check:focus + .btn,
  .use-bootstrap .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .use-bootstrap .btn.disabled,
  .use-bootstrap .btn:disabled,
  .use-bootstrap fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }
  .use-bootstrap .btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .use-bootstrap .btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca; }
  .use-bootstrap .btn-check:focus + .btn-primary,
  .use-bootstrap .btn-primary:focus {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .use-bootstrap .btn-check:active + .btn-primary,
  .use-bootstrap .btn-check:checked + .btn-primary,
  .use-bootstrap .btn-primary.active,
  .use-bootstrap .btn-primary:active,
  .use-bootstrap .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be; }
  .use-bootstrap .btn-check:active + .btn-primary:focus,
  .use-bootstrap .btn-check:checked + .btn-primary:focus,
  .use-bootstrap .btn-primary.active:focus,
  .use-bootstrap .btn-primary:active:focus,
  .use-bootstrap .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .use-bootstrap .btn-primary.disabled,
  .use-bootstrap .btn-primary:disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .use-bootstrap .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .use-bootstrap .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64; }
  .use-bootstrap .btn-check:focus + .btn-secondary,
  .use-bootstrap .btn-secondary:focus {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .use-bootstrap .btn-check:active + .btn-secondary,
  .use-bootstrap .btn-check:checked + .btn-secondary,
  .use-bootstrap .btn-secondary.active,
  .use-bootstrap .btn-secondary:active,
  .use-bootstrap .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e; }
  .use-bootstrap .btn-check:active + .btn-secondary:focus,
  .use-bootstrap .btn-check:checked + .btn-secondary:focus,
  .use-bootstrap .btn-secondary.active:focus,
  .use-bootstrap .btn-secondary:active:focus,
  .use-bootstrap .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .use-bootstrap .btn-secondary.disabled,
  .use-bootstrap .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .use-bootstrap .btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
  .use-bootstrap .btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43; }
  .use-bootstrap .btn-check:focus + .btn-success,
  .use-bootstrap .btn-success:focus {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .use-bootstrap .btn-check:active + .btn-success,
  .use-bootstrap .btn-check:checked + .btn-success,
  .use-bootstrap .btn-success.active,
  .use-bootstrap .btn-success:active,
  .use-bootstrap .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f; }
  .use-bootstrap .btn-check:active + .btn-success:focus,
  .use-bootstrap .btn-check:checked + .btn-success:focus,
  .use-bootstrap .btn-success.active:focus,
  .use-bootstrap .btn-success:active:focus,
  .use-bootstrap .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .use-bootstrap .btn-success.disabled,
  .use-bootstrap .btn-success:disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
  .use-bootstrap .btn-info {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .use-bootstrap .btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2; }
  .use-bootstrap .btn-check:focus + .btn-info,
  .use-bootstrap .btn-info:focus {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .use-bootstrap .btn-check:active + .btn-info,
  .use-bootstrap .btn-check:checked + .btn-info,
  .use-bootstrap .btn-info.active,
  .use-bootstrap .btn-info:active,
  .use-bootstrap .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2; }
  .use-bootstrap .btn-check:active + .btn-info:focus,
  .use-bootstrap .btn-check:checked + .btn-info:focus,
  .use-bootstrap .btn-info.active:focus,
  .use-bootstrap .btn-info:active:focus,
  .use-bootstrap .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .use-bootstrap .btn-info.disabled,
  .use-bootstrap .btn-info:disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .use-bootstrap .btn-warning {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .use-bootstrap .btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720; }
  .use-bootstrap .btn-check:focus + .btn-warning,
  .use-bootstrap .btn-warning:focus {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .use-bootstrap .btn-check:active + .btn-warning,
  .use-bootstrap .btn-check:checked + .btn-warning,
  .use-bootstrap .btn-warning.active,
  .use-bootstrap .btn-warning:active,
  .use-bootstrap .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720; }
  .use-bootstrap .btn-check:active + .btn-warning:focus,
  .use-bootstrap .btn-check:checked + .btn-warning:focus,
  .use-bootstrap .btn-warning.active:focus,
  .use-bootstrap .btn-warning:active:focus,
  .use-bootstrap .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .use-bootstrap .btn-warning.disabled,
  .use-bootstrap .btn-warning:disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .use-bootstrap .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .use-bootstrap .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37; }
  .use-bootstrap .btn-check:focus + .btn-danger,
  .use-bootstrap .btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .use-bootstrap .btn-check:active + .btn-danger,
  .use-bootstrap .btn-check:checked + .btn-danger,
  .use-bootstrap .btn-danger.active,
  .use-bootstrap .btn-danger:active,
  .use-bootstrap .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834; }
  .use-bootstrap .btn-check:active + .btn-danger:focus,
  .use-bootstrap .btn-check:checked + .btn-danger:focus,
  .use-bootstrap .btn-danger.active:focus,
  .use-bootstrap .btn-danger:active:focus,
  .use-bootstrap .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .use-bootstrap .btn-danger.disabled,
  .use-bootstrap .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .use-bootstrap .btn-light {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .use-bootstrap .btn-light:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
  .use-bootstrap .btn-check:focus + .btn-light,
  .use-bootstrap .btn-light:focus {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .use-bootstrap .btn-check:active + .btn-light,
  .use-bootstrap .btn-check:checked + .btn-light,
  .use-bootstrap .btn-light.active,
  .use-bootstrap .btn-light:active,
  .use-bootstrap .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
  .use-bootstrap .btn-check:active + .btn-light:focus,
  .use-bootstrap .btn-check:checked + .btn-light:focus,
  .use-bootstrap .btn-light.active:focus,
  .use-bootstrap .btn-light:active:focus,
  .use-bootstrap .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .use-bootstrap .btn-light.disabled,
  .use-bootstrap .btn-light:disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .use-bootstrap .btn-dark {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .use-bootstrap .btn-dark:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21; }
  .use-bootstrap .btn-check:focus + .btn-dark,
  .use-bootstrap .btn-dark:focus {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }
  .use-bootstrap .btn-check:active + .btn-dark,
  .use-bootstrap .btn-check:checked + .btn-dark,
  .use-bootstrap .btn-dark.active,
  .use-bootstrap .btn-dark:active,
  .use-bootstrap .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f; }
  .use-bootstrap .btn-check:active + .btn-dark:focus,
  .use-bootstrap .btn-check:checked + .btn-dark:focus,
  .use-bootstrap .btn-dark.active:focus,
  .use-bootstrap .btn-dark:active:focus,
  .use-bootstrap .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }
  .use-bootstrap .btn-dark.disabled,
  .use-bootstrap .btn-dark:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .use-bootstrap .btn-outline-primary {
    color: #0d6efd;
    border-color: #0d6efd; }
  .use-bootstrap .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .use-bootstrap .btn-check:focus + .btn-outline-primary,
  .use-bootstrap .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .use-bootstrap .btn-check:active + .btn-outline-primary,
  .use-bootstrap .btn-check:checked + .btn-outline-primary,
  .use-bootstrap .btn-outline-primary.active,
  .use-bootstrap .btn-outline-primary.dropdown-toggle.show,
  .use-bootstrap .btn-outline-primary:active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .use-bootstrap .btn-check:active + .btn-outline-primary:focus,
  .use-bootstrap .btn-check:checked + .btn-outline-primary:focus,
  .use-bootstrap .btn-outline-primary.active:focus,
  .use-bootstrap .btn-outline-primary.dropdown-toggle.show:focus,
  .use-bootstrap .btn-outline-primary:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .use-bootstrap .btn-outline-primary.disabled,
  .use-bootstrap .btn-outline-primary:disabled {
    color: #0d6efd;
    background-color: transparent; }
  .use-bootstrap .btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d; }
  .use-bootstrap .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .use-bootstrap .btn-check:focus + .btn-outline-secondary,
  .use-bootstrap .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .use-bootstrap .btn-check:active + .btn-outline-secondary,
  .use-bootstrap .btn-check:checked + .btn-outline-secondary,
  .use-bootstrap .btn-outline-secondary.active,
  .use-bootstrap .btn-outline-secondary.dropdown-toggle.show,
  .use-bootstrap .btn-outline-secondary:active {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .use-bootstrap .btn-check:active + .btn-outline-secondary:focus,
  .use-bootstrap .btn-check:checked + .btn-outline-secondary:focus,
  .use-bootstrap .btn-outline-secondary.active:focus,
  .use-bootstrap .btn-outline-secondary.dropdown-toggle.show:focus,
  .use-bootstrap .btn-outline-secondary:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .use-bootstrap .btn-outline-secondary.disabled,
  .use-bootstrap .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .use-bootstrap .btn-outline-success {
    color: #198754;
    border-color: #198754; }
  .use-bootstrap .btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
  .use-bootstrap .btn-check:focus + .btn-outline-success,
  .use-bootstrap .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .use-bootstrap .btn-check:active + .btn-outline-success,
  .use-bootstrap .btn-check:checked + .btn-outline-success,
  .use-bootstrap .btn-outline-success.active,
  .use-bootstrap .btn-outline-success.dropdown-toggle.show,
  .use-bootstrap .btn-outline-success:active {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
  .use-bootstrap .btn-check:active + .btn-outline-success:focus,
  .use-bootstrap .btn-check:checked + .btn-outline-success:focus,
  .use-bootstrap .btn-outline-success.active:focus,
  .use-bootstrap .btn-outline-success.dropdown-toggle.show:focus,
  .use-bootstrap .btn-outline-success:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .use-bootstrap .btn-outline-success.disabled,
  .use-bootstrap .btn-outline-success:disabled {
    color: #198754;
    background-color: transparent; }
  .use-bootstrap .btn-outline-info {
    color: #0dcaf0;
    border-color: #0dcaf0; }
  .use-bootstrap .btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .use-bootstrap .btn-check:focus + .btn-outline-info,
  .use-bootstrap .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .use-bootstrap .btn-check:active + .btn-outline-info,
  .use-bootstrap .btn-check:checked + .btn-outline-info,
  .use-bootstrap .btn-outline-info.active,
  .use-bootstrap .btn-outline-info.dropdown-toggle.show,
  .use-bootstrap .btn-outline-info:active {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .use-bootstrap .btn-check:active + .btn-outline-info:focus,
  .use-bootstrap .btn-check:checked + .btn-outline-info:focus,
  .use-bootstrap .btn-outline-info.active:focus,
  .use-bootstrap .btn-outline-info.dropdown-toggle.show:focus,
  .use-bootstrap .btn-outline-info:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .use-bootstrap .btn-outline-info.disabled,
  .use-bootstrap .btn-outline-info:disabled {
    color: #0dcaf0;
    background-color: transparent; }
  .use-bootstrap .btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107; }
  .use-bootstrap .btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .use-bootstrap .btn-check:focus + .btn-outline-warning,
  .use-bootstrap .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .use-bootstrap .btn-check:active + .btn-outline-warning,
  .use-bootstrap .btn-check:checked + .btn-outline-warning,
  .use-bootstrap .btn-outline-warning.active,
  .use-bootstrap .btn-outline-warning.dropdown-toggle.show,
  .use-bootstrap .btn-outline-warning:active {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .use-bootstrap .btn-check:active + .btn-outline-warning:focus,
  .use-bootstrap .btn-check:checked + .btn-outline-warning:focus,
  .use-bootstrap .btn-outline-warning.active:focus,
  .use-bootstrap .btn-outline-warning.dropdown-toggle.show:focus,
  .use-bootstrap .btn-outline-warning:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .use-bootstrap .btn-outline-warning.disabled,
  .use-bootstrap .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .use-bootstrap .btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545; }
  .use-bootstrap .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .use-bootstrap .btn-check:focus + .btn-outline-danger,
  .use-bootstrap .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .use-bootstrap .btn-check:active + .btn-outline-danger,
  .use-bootstrap .btn-check:checked + .btn-outline-danger,
  .use-bootstrap .btn-outline-danger.active,
  .use-bootstrap .btn-outline-danger.dropdown-toggle.show,
  .use-bootstrap .btn-outline-danger:active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .use-bootstrap .btn-check:active + .btn-outline-danger:focus,
  .use-bootstrap .btn-check:checked + .btn-outline-danger:focus,
  .use-bootstrap .btn-outline-danger.active:focus,
  .use-bootstrap .btn-outline-danger.dropdown-toggle.show:focus,
  .use-bootstrap .btn-outline-danger:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .use-bootstrap .btn-outline-danger.disabled,
  .use-bootstrap .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .use-bootstrap .btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa; }
  .use-bootstrap .btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .use-bootstrap .btn-check:focus + .btn-outline-light,
  .use-bootstrap .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .use-bootstrap .btn-check:active + .btn-outline-light,
  .use-bootstrap .btn-check:checked + .btn-outline-light,
  .use-bootstrap .btn-outline-light.active,
  .use-bootstrap .btn-outline-light.dropdown-toggle.show,
  .use-bootstrap .btn-outline-light:active {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .use-bootstrap .btn-check:active + .btn-outline-light:focus,
  .use-bootstrap .btn-check:checked + .btn-outline-light:focus,
  .use-bootstrap .btn-outline-light.active:focus,
  .use-bootstrap .btn-outline-light.dropdown-toggle.show:focus,
  .use-bootstrap .btn-outline-light:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .use-bootstrap .btn-outline-light.disabled,
  .use-bootstrap .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .use-bootstrap .btn-outline-dark {
    color: #212529;
    border-color: #212529; }
  .use-bootstrap .btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .use-bootstrap .btn-check:focus + .btn-outline-dark,
  .use-bootstrap .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }
  .use-bootstrap .btn-check:active + .btn-outline-dark,
  .use-bootstrap .btn-check:checked + .btn-outline-dark,
  .use-bootstrap .btn-outline-dark.active,
  .use-bootstrap .btn-outline-dark.dropdown-toggle.show,
  .use-bootstrap .btn-outline-dark:active {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .use-bootstrap .btn-check:active + .btn-outline-dark:focus,
  .use-bootstrap .btn-check:checked + .btn-outline-dark:focus,
  .use-bootstrap .btn-outline-dark.active:focus,
  .use-bootstrap .btn-outline-dark.dropdown-toggle.show:focus,
  .use-bootstrap .btn-outline-dark:active:focus {
    box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }
  .use-bootstrap .btn-outline-dark.disabled,
  .use-bootstrap .btn-outline-dark:disabled {
    color: #212529;
    background-color: transparent; }
  .use-bootstrap .btn-link {
    font-weight: 400;
    color: #0d6efd;
    text-decoration: underline; }
  .use-bootstrap .btn-link:hover {
    color: #0a58ca; }
  .use-bootstrap .btn-link.disabled,
  .use-bootstrap .btn-link:disabled {
    color: #6c757d; }
  .use-bootstrap .btn-group-lg > .btn,
  .use-bootstrap .btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem; }
  .use-bootstrap .btn-group-sm > .btn,
  .use-bootstrap .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem; }
  .use-bootstrap .fade {
    transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .fade {
      transition: none; } }
  .use-bootstrap .fade:not(.show) {
    opacity: 0; }
  .use-bootstrap .collapse:not(.show) {
    display: none; }
  .use-bootstrap .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .collapsing {
      transition: none; } }
  .use-bootstrap .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .collapsing.collapse-horizontal {
      transition: none; } }
  .use-bootstrap .dropdown,
  .use-bootstrap .dropend,
  .use-bootstrap .dropstart,
  .use-bootstrap .dropup {
    position: relative; }
  .use-bootstrap .dropdown-toggle {
    white-space: nowrap; }
  .use-bootstrap .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .use-bootstrap .dropdown-toggle:empty::after {
    margin-left: 0; }
  .use-bootstrap .dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }
  .use-bootstrap .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }
  .use-bootstrap .dropdown-menu-start {
    --bs-position: start; }
  .use-bootstrap .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }
  .use-bootstrap .dropdown-menu-end {
    --bs-position: end; }
  .use-bootstrap .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }
  @media (min-width: 576px) {
    .use-bootstrap .dropdown-menu-sm-start {
      --bs-position: start; }
    .use-bootstrap .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
    .use-bootstrap .dropdown-menu-sm-end {
      --bs-position: end; }
    .use-bootstrap .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }
  @media (min-width: 768px) {
    .use-bootstrap .dropdown-menu-md-start {
      --bs-position: start; }
    .use-bootstrap .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
    .use-bootstrap .dropdown-menu-md-end {
      --bs-position: end; }
    .use-bootstrap .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }
  @media (min-width: 992px) {
    .use-bootstrap .dropdown-menu-lg-start {
      --bs-position: start; }
    .use-bootstrap .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
    .use-bootstrap .dropdown-menu-lg-end {
      --bs-position: end; }
    .use-bootstrap .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }
  @media (min-width: 1200px) {
    .use-bootstrap .dropdown-menu-xl-start {
      --bs-position: start; }
    .use-bootstrap .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
    .use-bootstrap .dropdown-menu-xl-end {
      --bs-position: end; }
    .use-bootstrap .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }
  @media (min-width: 1400px) {
    .use-bootstrap .dropdown-menu-xxl-start {
      --bs-position: start; }
    .use-bootstrap .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
    .use-bootstrap .dropdown-menu-xxl-end {
      --bs-position: end; }
    .use-bootstrap .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }
  .use-bootstrap .dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem; }
  .use-bootstrap .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent; }
  .use-bootstrap .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }
  .use-bootstrap .dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem; }
  .use-bootstrap .dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid; }
  .use-bootstrap .dropend .dropdown-toggle:empty::after {
    margin-left: 0; }
  .use-bootstrap .dropend .dropdown-toggle::after {
    vertical-align: 0; }
  .use-bootstrap .dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem; }
  .use-bootstrap .dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: ""; }
  .use-bootstrap .dropstart .dropdown-toggle::after {
    display: none; }
  .use-bootstrap .dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent; }
  .use-bootstrap .dropstart .dropdown-toggle:empty::after {
    margin-left: 0; }
  .use-bootstrap .dropstart .dropdown-toggle::before {
    vertical-align: 0; }
  .use-bootstrap .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, 0.15); }
  .use-bootstrap .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
  .use-bootstrap .dropdown-item:focus,
  .use-bootstrap .dropdown-item:hover {
    color: #1e2125;
    background-color: #e9ecef; }
  .use-bootstrap .dropdown-item.active,
  .use-bootstrap .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd; }
  .use-bootstrap .dropdown-item.disabled,
  .use-bootstrap .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }
  .use-bootstrap .dropdown-menu.show {
    display: block; }
  .use-bootstrap .dropdown-header {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap; }
  .use-bootstrap .dropdown-item-text {
    display: block;
    padding: 0.25rem 1rem;
    color: #212529; }
  .use-bootstrap .dropdown-menu-dark {
    color: #dee2e6;
    background-color: #343a40;
    border-color: rgba(0, 0, 0, 0.15); }
  .use-bootstrap .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
  .use-bootstrap .dropdown-menu-dark .dropdown-item:focus,
  .use-bootstrap .dropdown-menu-dark .dropdown-item:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15); }
  .use-bootstrap .dropdown-menu-dark .dropdown-item.active,
  .use-bootstrap .dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: #0d6efd; }
  .use-bootstrap .dropdown-menu-dark .dropdown-item.disabled,
  .use-bootstrap .dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd; }
  .use-bootstrap .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .use-bootstrap .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .use-bootstrap .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }
  .use-bootstrap .btn-group,
  .use-bootstrap .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; }
  .use-bootstrap .btn-group-vertical > .btn,
  .use-bootstrap .btn-group > .btn {
    position: relative;
    flex: 1 1 auto; }
  .use-bootstrap .btn-group-vertical > .btn-check:checked + .btn,
  .use-bootstrap .btn-group-vertical > .btn-check:focus + .btn,
  .use-bootstrap .btn-group-vertical > .btn.active,
  .use-bootstrap .btn-group-vertical > .btn:active,
  .use-bootstrap .btn-group-vertical > .btn:focus,
  .use-bootstrap .btn-group-vertical > .btn:hover,
  .use-bootstrap .btn-group > .btn-check:checked + .btn,
  .use-bootstrap .btn-group > .btn-check:focus + .btn,
  .use-bootstrap .btn-group > .btn.active,
  .use-bootstrap .btn-group > .btn:active,
  .use-bootstrap .btn-group > .btn:focus,
  .use-bootstrap .btn-group > .btn:hover {
    z-index: 1; }
  .use-bootstrap .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .use-bootstrap .btn-toolbar .input-group {
    width: auto; }
  .use-bootstrap .btn-group > .btn-group:not(:first-child),
  .use-bootstrap .btn-group > .btn:not(:first-child) {
    margin-left: -1px; }
  .use-bootstrap .btn-group > .btn-group:not(:last-child) > .btn,
  .use-bootstrap .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .use-bootstrap .btn-group > .btn-group:not(:first-child) > .btn,
  .use-bootstrap .btn-group > .btn:nth-child(n + 3),
  .use-bootstrap .btn-group > :not(.btn-check) + .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .use-bootstrap .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem; }
  .use-bootstrap .dropdown-toggle-split::after,
  .use-bootstrap .dropend .dropdown-toggle-split::after,
  .use-bootstrap .dropup .dropdown-toggle-split::after {
    margin-left: 0; }
  .use-bootstrap .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }
  .use-bootstrap .btn-group-sm > .btn + .dropdown-toggle-split,
  .use-bootstrap .btn-sm + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem; }
  .use-bootstrap .btn-group-lg > .btn + .dropdown-toggle-split,
  .use-bootstrap .btn-lg + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  .use-bootstrap .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
  .use-bootstrap .btn-group-vertical > .btn,
  .use-bootstrap .btn-group-vertical > .btn-group {
    width: 100%; }
  .use-bootstrap .btn-group-vertical > .btn-group:not(:first-child),
  .use-bootstrap .btn-group-vertical > .btn:not(:first-child) {
    margin-top: -1px; }
  .use-bootstrap .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .use-bootstrap .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .use-bootstrap .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .use-bootstrap .btn-group-vertical > .btn ~ .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .use-bootstrap .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  .use-bootstrap .nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .nav-link {
      transition: none; } }
  .use-bootstrap .nav-link:focus,
  .use-bootstrap .nav-link:hover {
    color: #0a58ca; }
  .use-bootstrap .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }
  .use-bootstrap .nav-tabs {
    border-bottom: 1px solid #dee2e6; }
  .use-bootstrap .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .use-bootstrap .nav-tabs .nav-link:focus,
  .use-bootstrap .nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate; }
  .use-bootstrap .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent; }
  .use-bootstrap .nav-tabs .nav-item.show .nav-link,
  .use-bootstrap .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .use-bootstrap .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .use-bootstrap .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0.25rem; }
  .use-bootstrap .nav-pills .nav-link.active,
  .use-bootstrap .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #0d6efd; }
  .use-bootstrap .nav-fill .nav-item,
  .use-bootstrap .nav-fill > .nav-link {
    flex: 1 1 auto;
    text-align: center; }
  .use-bootstrap .nav-justified .nav-item,
  .use-bootstrap .nav-justified > .nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center; }
  .use-bootstrap .nav-fill .nav-item .nav-link,
  .use-bootstrap .nav-justified .nav-item .nav-link {
    width: 100%; }
  .use-bootstrap .tab-content > .tab-pane {
    display: none; }
  .use-bootstrap .tab-content > .active {
    display: block; }
  .use-bootstrap .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .use-bootstrap .navbar > .container,
  .use-bootstrap .navbar > .container-fluid,
  .use-bootstrap .navbar > .container-lg,
  .use-bootstrap .navbar > .container-md,
  .use-bootstrap .navbar > .container-sm,
  .use-bootstrap .navbar > .container-xl,
  .use-bootstrap .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }
  .use-bootstrap .navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap; }
  .use-bootstrap .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  .use-bootstrap .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .use-bootstrap .navbar-nav .dropdown-menu {
    position: static; }
  .use-bootstrap .navbar-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .use-bootstrap .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center; }
  .use-bootstrap .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .navbar-toggler {
      transition: none; } }
  .use-bootstrap .navbar-toggler:hover {
    text-decoration: none; }
  .use-bootstrap .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }
  .use-bootstrap .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%; }
  .use-bootstrap .navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto; }
  @media (min-width: 576px) {
    .use-bootstrap .navbar-expand-sm {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    .use-bootstrap .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
    .use-bootstrap .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute; }
    .use-bootstrap .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .use-bootstrap .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .use-bootstrap .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .use-bootstrap .navbar-expand-sm .navbar-toggler {
      display: none; }
    .use-bootstrap .navbar-expand-sm .offcanvas-header {
      display: none; }
    .use-bootstrap .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .use-bootstrap .navbar-expand-sm .offcanvas-bottom,
    .use-bootstrap .navbar-expand-sm .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .use-bootstrap .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }
  @media (min-width: 768px) {
    .use-bootstrap .navbar-expand-md {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    .use-bootstrap .navbar-expand-md .navbar-nav {
      flex-direction: row; }
    .use-bootstrap .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute; }
    .use-bootstrap .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .use-bootstrap .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .use-bootstrap .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .use-bootstrap .navbar-expand-md .navbar-toggler {
      display: none; }
    .use-bootstrap .navbar-expand-md .offcanvas-header {
      display: none; }
    .use-bootstrap .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .use-bootstrap .navbar-expand-md .offcanvas-bottom,
    .use-bootstrap .navbar-expand-md .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .use-bootstrap .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }
  @media (min-width: 992px) {
    .use-bootstrap .navbar-expand-lg {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    .use-bootstrap .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
    .use-bootstrap .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute; }
    .use-bootstrap .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .use-bootstrap .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .use-bootstrap .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .use-bootstrap .navbar-expand-lg .navbar-toggler {
      display: none; }
    .use-bootstrap .navbar-expand-lg .offcanvas-header {
      display: none; }
    .use-bootstrap .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .use-bootstrap .navbar-expand-lg .offcanvas-bottom,
    .use-bootstrap .navbar-expand-lg .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .use-bootstrap .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }
  @media (min-width: 1200px) {
    .use-bootstrap .navbar-expand-xl {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    .use-bootstrap .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
    .use-bootstrap .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute; }
    .use-bootstrap .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .use-bootstrap .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .use-bootstrap .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .use-bootstrap .navbar-expand-xl .navbar-toggler {
      display: none; }
    .use-bootstrap .navbar-expand-xl .offcanvas-header {
      display: none; }
    .use-bootstrap .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .use-bootstrap .navbar-expand-xl .offcanvas-bottom,
    .use-bootstrap .navbar-expand-xl .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .use-bootstrap .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }
  @media (min-width: 1400px) {
    .use-bootstrap .navbar-expand-xxl {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    .use-bootstrap .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
    .use-bootstrap .navbar-expand-xxl .navbar-nav .dropdown-menu {
      position: absolute; }
    .use-bootstrap .navbar-expand-xxl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .use-bootstrap .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .use-bootstrap .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .use-bootstrap .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .use-bootstrap .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .use-bootstrap .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .use-bootstrap .navbar-expand-xxl .offcanvas-bottom,
    .use-bootstrap .navbar-expand-xxl .offcanvas-top {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .use-bootstrap .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }
  .use-bootstrap .navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .use-bootstrap .navbar-expand .navbar-nav {
    flex-direction: row; }
  .use-bootstrap .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute; }
  .use-bootstrap .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .use-bootstrap .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .use-bootstrap .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .use-bootstrap .navbar-expand .navbar-toggler {
    display: none; }
  .use-bootstrap .navbar-expand .offcanvas-header {
    display: none; }
  .use-bootstrap .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .use-bootstrap .navbar-expand .offcanvas-bottom,
  .use-bootstrap .navbar-expand .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .use-bootstrap .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }
  .use-bootstrap .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9); }
  .use-bootstrap .navbar-light .navbar-brand:focus,
  .use-bootstrap .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }
  .use-bootstrap .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.55); }
  .use-bootstrap .navbar-light .navbar-nav .nav-link:focus,
  .use-bootstrap .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .use-bootstrap .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }
  .use-bootstrap .navbar-light .navbar-nav .nav-link.active,
  .use-bootstrap .navbar-light .navbar-nav .show > .nav-link {
    color: rgba(0, 0, 0, 0.9); }
  .use-bootstrap .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.55);
    border-color: rgba(0, 0, 0, 0.1); }
  .use-bootstrap .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  .use-bootstrap .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.55); }
  .use-bootstrap .navbar-light .navbar-text a,
  .use-bootstrap .navbar-light .navbar-text a:focus,
  .use-bootstrap .navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, 0.9); }
  .use-bootstrap .navbar-dark .navbar-brand {
    color: #fff; }
  .use-bootstrap .navbar-dark .navbar-brand:focus,
  .use-bootstrap .navbar-dark .navbar-brand:hover {
    color: #fff; }
  .use-bootstrap .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.55); }
  .use-bootstrap .navbar-dark .navbar-nav .nav-link:focus,
  .use-bootstrap .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .use-bootstrap .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }
  .use-bootstrap .navbar-dark .navbar-nav .nav-link.active,
  .use-bootstrap .navbar-dark .navbar-nav .show > .nav-link {
    color: #fff; }
  .use-bootstrap .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.55);
    border-color: rgba(255, 255, 255, 0.1); }
  .use-bootstrap .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }
  .use-bootstrap .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.55); }
  .use-bootstrap .navbar-dark .navbar-text a,
  .use-bootstrap .navbar-dark .navbar-text a:focus,
  .use-bootstrap .navbar-dark .navbar-text a:hover {
    color: #fff; }
  .use-bootstrap .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem; }
  .use-bootstrap .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .use-bootstrap .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
  .use-bootstrap .card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .use-bootstrap .card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  .use-bootstrap .card > .card-header + .list-group,
  .use-bootstrap .card > .list-group + .card-footer {
    border-top: 0; }
  .use-bootstrap .card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem; }
  .use-bootstrap .card-title {
    margin-bottom: 0.5rem; }
  .use-bootstrap .card-subtitle {
    margin-top: -0.25rem;
    margin-bottom: 0; }
  .use-bootstrap .card-text:last-child {
    margin-bottom: 0; }
  .use-bootstrap .card-link + .card-link {
    margin-left: 1rem; }
  .use-bootstrap .card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .use-bootstrap .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .use-bootstrap .card-footer {
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .use-bootstrap .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }
  .use-bootstrap .card-header-tabs {
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
    margin-left: -0.5rem;
    border-bottom: 0; }
  .use-bootstrap .card-header-pills {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
  .use-bootstrap .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(0.25rem - 1px); }
  .use-bootstrap .card-img,
  .use-bootstrap .card-img-bottom,
  .use-bootstrap .card-img-top {
    width: 100%; }
  .use-bootstrap .card-img,
  .use-bootstrap .card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .use-bootstrap .card-img,
  .use-bootstrap .card-img-bottom {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  .use-bootstrap .card-group > .card {
    margin-bottom: 0.75rem; }
  @media (min-width: 576px) {
    .use-bootstrap .card-group {
      display: flex;
      flex-flow: row wrap; }
    .use-bootstrap .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
    .use-bootstrap .card-group > .card + .card {
      margin-left: 0;
      border-left: 0; }
    .use-bootstrap .card-group > .card:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .use-bootstrap .card-group > .card:not(:last-child) .card-header,
    .use-bootstrap .card-group > .card:not(:last-child) .card-img-top {
      border-top-right-radius: 0; }
    .use-bootstrap .card-group > .card:not(:last-child) .card-footer,
    .use-bootstrap .card-group > .card:not(:last-child) .card-img-bottom {
      border-bottom-right-radius: 0; }
    .use-bootstrap .card-group > .card:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .use-bootstrap .card-group > .card:not(:first-child) .card-header,
    .use-bootstrap .card-group > .card:not(:first-child) .card-img-top {
      border-top-left-radius: 0; }
    .use-bootstrap .card-group > .card:not(:first-child) .card-footer,
    .use-bootstrap .card-group > .card:not(:first-child) .card-img-bottom {
      border-bottom-left-radius: 0; } }
  .use-bootstrap .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .accordion-button {
      transition: none; } }
  .use-bootstrap .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
  .use-bootstrap .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg); }
  .use-bootstrap .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .accordion-button::after {
      transition: none; } }
  .use-bootstrap .accordion-button:hover {
    z-index: 2; }
  .use-bootstrap .accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .use-bootstrap .accordion-header {
    margin-bottom: 0; }
  .use-bootstrap .accordion-item {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125); }
  .use-bootstrap .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .use-bootstrap .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .use-bootstrap .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .use-bootstrap .accordion-item:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .use-bootstrap .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px); }
  .use-bootstrap .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .use-bootstrap .accordion-body {
    padding: 1rem 1.25rem; }
  .use-bootstrap .accordion-flush .accordion-collapse {
    border-width: 0; }
  .use-bootstrap .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0; }
  .use-bootstrap .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .use-bootstrap .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .use-bootstrap .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }
  .use-bootstrap .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none; }
  .use-bootstrap .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
  .use-bootstrap .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/"); }
  .use-bootstrap .breadcrumb-item.active {
    color: #6c757d; }
  .use-bootstrap .pagination {
    display: flex;
    padding-left: 0;
    list-style: none; }
  .use-bootstrap .page-link {
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .page-link {
      transition: none; } }
  .use-bootstrap .page-link:hover {
    z-index: 2;
    color: #0a58ca;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .use-bootstrap .page-link:focus {
    z-index: 3;
    color: #0a58ca;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .use-bootstrap .page-item:not(:first-child) .page-link {
    margin-left: -1px; }
  .use-bootstrap .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .use-bootstrap .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6; }
  .use-bootstrap .page-link {
    padding: 0.375rem 0.75rem; }
  .use-bootstrap .page-item:first-child .page-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .use-bootstrap .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem; }
  .use-bootstrap .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem; }
  .use-bootstrap .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem; }
  .use-bootstrap .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem; }
  .use-bootstrap .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem; }
  .use-bootstrap .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem; }
  .use-bootstrap .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem; }
  .use-bootstrap .badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem; }
  .use-bootstrap .badge:empty {
    display: none; }
  .use-bootstrap .btn .badge {
    position: relative;
    top: -1px; }
  .use-bootstrap .alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem; }
  .use-bootstrap .alert-heading {
    color: inherit; }
  .use-bootstrap .alert-link {
    font-weight: 700; }
  .use-bootstrap .alert-dismissible {
    padding-right: 3rem; }
  .use-bootstrap .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }
  .use-bootstrap .alert-primary {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe; }
  .use-bootstrap .alert-primary .alert-link {
    color: #06357a; }
  .use-bootstrap .alert-secondary {
    color: #41464b;
    background-color: #e2e3e5;
    border-color: #d3d6d8; }
  .use-bootstrap .alert-secondary .alert-link {
    color: #34383c; }
  .use-bootstrap .alert-success {
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc; }
  .use-bootstrap .alert-success .alert-link {
    color: #0c4128; }
  .use-bootstrap .alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb; }
  .use-bootstrap .alert-info .alert-link {
    color: #04414d; }
  .use-bootstrap .alert-warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5; }
  .use-bootstrap .alert-warning .alert-link {
    color: #523e02; }
  .use-bootstrap .alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7; }
  .use-bootstrap .alert-danger .alert-link {
    color: #6a1a21; }
  .use-bootstrap .alert-light {
    color: #636464;
    background-color: #fefefe;
    border-color: #fdfdfe; }
  .use-bootstrap .alert-light .alert-link {
    color: #4f5050; }
  .use-bootstrap .alert-dark {
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf; }
  .use-bootstrap .alert-dark .alert-link {
    color: #101214; }

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }
  .use-bootstrap .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem; }
  .use-bootstrap .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0d6efd;
    transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .progress-bar {
      transition: none; } }
  .use-bootstrap .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem; }
  .use-bootstrap .progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .progress-bar-animated {
      -webkit-animation: none;
      animation: none; } }
  .use-bootstrap .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem; }
  .use-bootstrap .list-group-numbered {
    list-style-type: none;
    counter-reset: section; }
  .use-bootstrap .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }
  .use-bootstrap .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit; }
  .use-bootstrap .list-group-item-action:focus,
  .use-bootstrap .list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .use-bootstrap .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }
  .use-bootstrap .list-group-item {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125); }
  .use-bootstrap .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .use-bootstrap .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .use-bootstrap .list-group-item.disabled,
  .use-bootstrap .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .use-bootstrap .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .use-bootstrap .list-group-item + .list-group-item {
    border-top-width: 0; }
  .use-bootstrap .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px; }
  .use-bootstrap .list-group-horizontal {
    flex-direction: row; }
  .use-bootstrap .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .use-bootstrap .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .use-bootstrap .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .use-bootstrap .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
  .use-bootstrap .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px; }
  @media (min-width: 576px) {
    .use-bootstrap .list-group-horizontal-sm {
      flex-direction: row; }
    .use-bootstrap .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .use-bootstrap .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .use-bootstrap .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .use-bootstrap .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
    .use-bootstrap .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; } }
  @media (min-width: 768px) {
    .use-bootstrap .list-group-horizontal-md {
      flex-direction: row; }
    .use-bootstrap .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .use-bootstrap .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .use-bootstrap .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .use-bootstrap .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
    .use-bootstrap .list-group-horizontal-md > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; } }
  @media (min-width: 992px) {
    .use-bootstrap .list-group-horizontal-lg {
      flex-direction: row; }
    .use-bootstrap .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .use-bootstrap .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .use-bootstrap .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .use-bootstrap .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
    .use-bootstrap .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; } }
  @media (min-width: 1200px) {
    .use-bootstrap .list-group-horizontal-xl {
      flex-direction: row; }
    .use-bootstrap .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .use-bootstrap .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .use-bootstrap .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .use-bootstrap .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
    .use-bootstrap .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; } }
  @media (min-width: 1400px) {
    .use-bootstrap .list-group-horizontal-xxl {
      flex-direction: row; }
    .use-bootstrap .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .use-bootstrap .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .use-bootstrap .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .use-bootstrap .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
    .use-bootstrap .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; } }
  .use-bootstrap .list-group-flush {
    border-radius: 0; }
  .use-bootstrap .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
  .use-bootstrap .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0; }
  .use-bootstrap .list-group-item-primary {
    color: #084298;
    background-color: #cfe2ff; }
  .use-bootstrap .list-group-item-primary.list-group-item-action:focus,
  .use-bootstrap .list-group-item-primary.list-group-item-action:hover {
    color: #084298;
    background-color: #bacbe6; }
  .use-bootstrap .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298; }
  .use-bootstrap .list-group-item-secondary {
    color: #41464b;
    background-color: #e2e3e5; }
  .use-bootstrap .list-group-item-secondary.list-group-item-action:focus,
  .use-bootstrap .list-group-item-secondary.list-group-item-action:hover {
    color: #41464b;
    background-color: #cbccce; }
  .use-bootstrap .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b; }
  .use-bootstrap .list-group-item-success {
    color: #0f5132;
    background-color: #d1e7dd; }
  .use-bootstrap .list-group-item-success.list-group-item-action:focus,
  .use-bootstrap .list-group-item-success.list-group-item-action:hover {
    color: #0f5132;
    background-color: #bcd0c7; }
  .use-bootstrap .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132; }
  .use-bootstrap .list-group-item-info {
    color: #055160;
    background-color: #cff4fc; }
  .use-bootstrap .list-group-item-info.list-group-item-action:focus,
  .use-bootstrap .list-group-item-info.list-group-item-action:hover {
    color: #055160;
    background-color: #badce3; }
  .use-bootstrap .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160; }
  .use-bootstrap .list-group-item-warning {
    color: #664d03;
    background-color: #fff3cd; }
  .use-bootstrap .list-group-item-warning.list-group-item-action:focus,
  .use-bootstrap .list-group-item-warning.list-group-item-action:hover {
    color: #664d03;
    background-color: #e6dbb9; }
  .use-bootstrap .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03; }
  .use-bootstrap .list-group-item-danger {
    color: #842029;
    background-color: #f8d7da; }
  .use-bootstrap .list-group-item-danger.list-group-item-action:focus,
  .use-bootstrap .list-group-item-danger.list-group-item-action:hover {
    color: #842029;
    background-color: #dfc2c4; }
  .use-bootstrap .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }
  .use-bootstrap .list-group-item-light {
    color: #636464;
    background-color: #fefefe; }
  .use-bootstrap .list-group-item-light.list-group-item-action:focus,
  .use-bootstrap .list-group-item-light.list-group-item-action:hover {
    color: #636464;
    background-color: #e5e5e5; }
  .use-bootstrap .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464; }
  .use-bootstrap .list-group-item-dark {
    color: #141619;
    background-color: #d3d3d4; }
  .use-bootstrap .list-group-item-dark.list-group-item-action:focus,
  .use-bootstrap .list-group-item-dark.list-group-item-action:hover {
    color: #141619;
    background-color: #bebebf; }
  .use-bootstrap .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619; }
  .use-bootstrap .btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5; }
  .use-bootstrap .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .use-bootstrap .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    opacity: 1; }
  .use-bootstrap .btn-close.disabled,
  .use-bootstrap .btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: 0.25; }
  .use-bootstrap .btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%); }
  .use-bootstrap .toast {
    width: 350px;
    max-width: 100%;
    font-size: 0.875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }
  .use-bootstrap .toast.showing {
    opacity: 0; }
  .use-bootstrap .toast:not(.show) {
    display: none; }
  .use-bootstrap .toast-container {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 100%;
    pointer-events: none; }
  .use-bootstrap .toast-container > :not(:last-child) {
    margin-bottom: 0.75rem; }
  .use-bootstrap .toast-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px); }
  .use-bootstrap .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }
  .use-bootstrap .toast-body {
    padding: 0.75rem;
    word-wrap: break-word; }
  .use-bootstrap .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0; }
  .use-bootstrap .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
  .use-bootstrap .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .modal.fade .modal-dialog {
      transition: none; } }
  .use-bootstrap .modal.show .modal-dialog {
    transform: none; }
  .use-bootstrap .modal.modal-static .modal-dialog {
    transform: scale(1.02); }
  .use-bootstrap .modal-dialog-scrollable {
    height: calc(100% - 1rem); }
  .use-bootstrap .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .use-bootstrap .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }
  .use-bootstrap .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem); }
  .use-bootstrap .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0; }
  .use-bootstrap .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000; }
  .use-bootstrap .modal-backdrop.fade {
    opacity: 0; }
  .use-bootstrap .modal-backdrop.show {
    opacity: 0.5; }
  .use-bootstrap .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
  .use-bootstrap .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }
  .use-bootstrap .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  .use-bootstrap .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem; }
  .use-bootstrap .modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px); }
  .use-bootstrap .modal-footer > * {
    margin: 0.25rem; }
  @media (min-width: 576px) {
    .use-bootstrap .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    .use-bootstrap .modal-dialog-scrollable {
      height: calc(100% - 3.5rem); }
    .use-bootstrap .modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
    .use-bootstrap .modal-sm {
      max-width: 300px; } }
  @media (min-width: 992px) {
    .use-bootstrap .modal-lg,
    .use-bootstrap .modal-xl {
      max-width: 800px; } }
  @media (min-width: 1200px) {
    .use-bootstrap .modal-xl {
      max-width: 1140px; } }
  .use-bootstrap .modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
  .use-bootstrap .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .use-bootstrap .modal-fullscreen .modal-header {
    border-radius: 0; }
  .use-bootstrap .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .use-bootstrap .modal-fullscreen .modal-footer {
    border-radius: 0; }
  @media (max-width: 575.98px) {
    .use-bootstrap .modal-fullscreen-sm-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
    .use-bootstrap .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .use-bootstrap .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .use-bootstrap .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .use-bootstrap .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }
  @media (max-width: 767.98px) {
    .use-bootstrap .modal-fullscreen-md-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
    .use-bootstrap .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .use-bootstrap .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .use-bootstrap .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .use-bootstrap .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }
  @media (max-width: 991.98px) {
    .use-bootstrap .modal-fullscreen-lg-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
    .use-bootstrap .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .use-bootstrap .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .use-bootstrap .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .use-bootstrap .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }
  @media (max-width: 1199.98px) {
    .use-bootstrap .modal-fullscreen-xl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
    .use-bootstrap .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .use-bootstrap .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .use-bootstrap .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .use-bootstrap .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }
  @media (max-width: 1399.98px) {
    .use-bootstrap .modal-fullscreen-xxl-down {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0; }
    .use-bootstrap .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .use-bootstrap .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .use-bootstrap .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .use-bootstrap .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }
  .use-bootstrap .tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0; }
  .use-bootstrap .tooltip.show {
    opacity: 0.9; }
  .use-bootstrap .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
  .use-bootstrap .tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="top"],
  .use-bootstrap .bs-tooltip-top {
    padding: 0.4rem 0; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
  .use-bootstrap .bs-tooltip-top .tooltip-arrow {
    bottom: 0; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
  .use-bootstrap .bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="right"],
  .use-bootstrap .bs-tooltip-end {
    padding: 0 0.4rem; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
  .use-bootstrap .bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
  .use-bootstrap .bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="bottom"],
  .use-bootstrap .bs-tooltip-bottom {
    padding: 0.4rem 0; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
  .use-bootstrap .bs-tooltip-bottom .tooltip-arrow {
    top: 0; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
  .use-bootstrap .bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="left"],
  .use-bootstrap .bs-tooltip-start {
    padding: 0 0.4rem; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
  .use-bootstrap .bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
  .use-bootstrap .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
  .use-bootstrap .bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000; }
  .use-bootstrap .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem; }
  .use-bootstrap .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem; }
  .use-bootstrap .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
  .use-bootstrap .popover .popover-arrow::after,
  .use-bootstrap .popover .popover-arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid; }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
  .use-bootstrap .bs-popover-top > .popover-arrow {
    bottom: calc(-0.5rem - 1px); }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
  .use-bootstrap .bs-popover-top > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
  .use-bootstrap .bs-popover-top > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
  .use-bootstrap .bs-popover-end > .popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem; }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
  .use-bootstrap .bs-popover-end > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
  .use-bootstrap .bs-popover-end > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
  .use-bootstrap .bs-popover-bottom > .popover-arrow {
    top: calc(-0.5rem - 1px); }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
  .use-bootstrap .bs-popover-bottom > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
  .use-bootstrap .bs-popover-bottom > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
  .use-bootstrap .bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f0f0f0; }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
  .use-bootstrap .bs-popover-start > .popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem; }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
  .use-bootstrap .bs-popover-start > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .use-bootstrap .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
  .use-bootstrap .bs-popover-start > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }
  .use-bootstrap .popover-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
  .use-bootstrap .popover-header:empty {
    display: none; }
  .use-bootstrap .popover-body {
    padding: 1rem 1rem;
    color: #212529; }
  .use-bootstrap .carousel {
    position: relative; }
  .use-bootstrap .carousel.pointer-event {
    touch-action: pan-y; }
  .use-bootstrap .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }
  .use-bootstrap .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }
  .use-bootstrap .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .carousel-item {
      transition: none; } }
  .use-bootstrap .carousel-item-next,
  .use-bootstrap .carousel-item-prev,
  .use-bootstrap .carousel-item.active {
    display: block; }
  .use-bootstrap .active.carousel-item-end,
  .use-bootstrap .carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%); }
  .use-bootstrap .active.carousel-item-start,
  .use-bootstrap .carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%); }
  .use-bootstrap .carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none; }
  .use-bootstrap .carousel-fade .carousel-item-next.carousel-item-start,
  .use-bootstrap .carousel-fade .carousel-item-prev.carousel-item-end,
  .use-bootstrap .carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1; }
  .use-bootstrap .carousel-fade .active.carousel-item-end,
  .use-bootstrap .carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .carousel-fade .active.carousel-item-end,
    .use-bootstrap .carousel-fade .active.carousel-item-start {
      transition: none; } }
  .use-bootstrap .carousel-control-next,
  .use-bootstrap .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .carousel-control-next,
    .use-bootstrap .carousel-control-prev {
      transition: none; } }
  .use-bootstrap .carousel-control-next:focus,
  .use-bootstrap .carousel-control-next:hover,
  .use-bootstrap .carousel-control-prev:focus,
  .use-bootstrap .carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }
  .use-bootstrap .carousel-control-prev {
    left: 0; }
  .use-bootstrap .carousel-control-next {
    right: 0; }
  .use-bootstrap .carousel-control-next-icon,
  .use-bootstrap .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%; }
  .use-bootstrap .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }
  .use-bootstrap .carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }
  .use-bootstrap .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none; }
  .use-bootstrap .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .carousel-indicators [data-bs-target] {
      transition: none; } }
  .use-bootstrap .carousel-indicators .active {
    opacity: 1; }
  .use-bootstrap .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center; }
  .use-bootstrap .carousel-dark .carousel-control-next-icon,
  .use-bootstrap .carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100); }
  .use-bootstrap .carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000; }
  .use-bootstrap .carousel-dark .carousel-caption {
    color: #000; }

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }
  .use-bootstrap .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border; }
  .use-bootstrap .spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }
  .use-bootstrap .spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: 0.75s linear infinite spinner-grow;
    animation: 0.75s linear infinite spinner-grow; }
  .use-bootstrap .spinner-grow-sm {
    width: 1rem;
    height: 1rem; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .spinner-border,
    .use-bootstrap .spinner-grow {
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s; } }
  .use-bootstrap .offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .use-bootstrap .offcanvas {
      transition: none; } }
  .use-bootstrap .offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000; }
  .use-bootstrap .offcanvas-backdrop.fade {
    opacity: 0; }
  .use-bootstrap .offcanvas-backdrop.show {
    opacity: 0.5; }
  .use-bootstrap .offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem; }
  .use-bootstrap .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }
  .use-bootstrap .offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5; }
  .use-bootstrap .offcanvas-body {
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: auto; }
  .use-bootstrap .offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateX(-100%); }
  .use-bootstrap .offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateX(100%); }
  .use-bootstrap .offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateY(-100%); }
  .use-bootstrap .offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateY(100%); }
  .use-bootstrap .offcanvas.show {
    transform: none; }
  .use-bootstrap .placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: 0.5; }
  .use-bootstrap .placeholder.btn::before {
    display: inline-block;
    content: ""; }
  .use-bootstrap .placeholder-xs {
    min-height: 0.6em; }
  .use-bootstrap .placeholder-sm {
    min-height: 0.8em; }
  .use-bootstrap .placeholder-lg {
    min-height: 1.2em; }
  .use-bootstrap .placeholder-glow .placeholder {
    -webkit-animation: placeholder-glow 2s ease-in-out infinite;
    animation: placeholder-glow 2s ease-in-out infinite; }

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }
  .use-bootstrap .placeholder-wave {
    -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-animation: placeholder-wave 2s linear infinite;
    animation: placeholder-wave 2s linear infinite; }

@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%; } }

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%; } }
  .use-bootstrap .clearfix::after {
    display: block;
    clear: both;
    content: ""; }
  .use-bootstrap .link-primary {
    color: #0d6efd; }
  .use-bootstrap .link-primary:focus,
  .use-bootstrap .link-primary:hover {
    color: #0a58ca; }
  .use-bootstrap .link-secondary {
    color: #6c757d; }
  .use-bootstrap .link-secondary:focus,
  .use-bootstrap .link-secondary:hover {
    color: #565e64; }
  .use-bootstrap .link-success {
    color: #198754; }
  .use-bootstrap .link-success:focus,
  .use-bootstrap .link-success:hover {
    color: #146c43; }
  .use-bootstrap .link-info {
    color: #0dcaf0; }
  .use-bootstrap .link-info:focus,
  .use-bootstrap .link-info:hover {
    color: #3dd5f3; }
  .use-bootstrap .link-warning {
    color: #ffc107; }
  .use-bootstrap .link-warning:focus,
  .use-bootstrap .link-warning:hover {
    color: #ffcd39; }
  .use-bootstrap .link-danger {
    color: #dc3545; }
  .use-bootstrap .link-danger:focus,
  .use-bootstrap .link-danger:hover {
    color: #b02a37; }
  .use-bootstrap .link-light {
    color: #f8f9fa; }
  .use-bootstrap .link-light:focus,
  .use-bootstrap .link-light:hover {
    color: #f9fafb; }
  .use-bootstrap .link-dark {
    color: #212529; }
  .use-bootstrap .link-dark:focus,
  .use-bootstrap .link-dark:hover {
    color: #1a1e21; }
  .use-bootstrap .ratio {
    position: relative;
    width: 100%; }
  .use-bootstrap .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .use-bootstrap .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .use-bootstrap .ratio-1x1 {
    --bs-aspect-ratio: 100%; }
  .use-bootstrap .ratio-4x3 {
    --bs-aspect-ratio: 75%; }
  .use-bootstrap .ratio-16x9 {
    --bs-aspect-ratio: 56.25%; }
  .use-bootstrap .ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%; }
  .use-bootstrap .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  .use-bootstrap .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }
  .use-bootstrap .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; }
  @media (min-width: 576px) {
    .use-bootstrap .sticky-sm-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  @media (min-width: 768px) {
    .use-bootstrap .sticky-md-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  @media (min-width: 992px) {
    .use-bootstrap .sticky-lg-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  @media (min-width: 1200px) {
    .use-bootstrap .sticky-xl-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  @media (min-width: 1400px) {
    .use-bootstrap .sticky-xxl-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  .use-bootstrap .hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch; }
  .use-bootstrap .vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch; }
  .use-bootstrap .visually-hidden,
  .use-bootstrap .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important; }
  .use-bootstrap .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""; }
  .use-bootstrap .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .use-bootstrap .vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: 0.25; }
  .use-bootstrap .align-baseline {
    vertical-align: baseline !important; }
  .use-bootstrap .align-top {
    vertical-align: top !important; }
  .use-bootstrap .align-middle {
    vertical-align: middle !important; }
  .use-bootstrap .align-bottom {
    vertical-align: bottom !important; }
  .use-bootstrap .align-text-bottom {
    vertical-align: text-bottom !important; }
  .use-bootstrap .align-text-top {
    vertical-align: text-top !important; }
  .use-bootstrap .float-start {
    float: left !important; }
  .use-bootstrap .float-end {
    float: right !important; }
  .use-bootstrap .float-none {
    float: none !important; }
  .use-bootstrap .opacity-0 {
    opacity: 0 !important; }
  .use-bootstrap .opacity-25 {
    opacity: 0.25 !important; }
  .use-bootstrap .opacity-50 {
    opacity: 0.5 !important; }
  .use-bootstrap .opacity-75 {
    opacity: 0.75 !important; }
  .use-bootstrap .opacity-100 {
    opacity: 1 !important; }
  .use-bootstrap .overflow-auto {
    overflow: auto !important; }
  .use-bootstrap .overflow-hidden {
    overflow: hidden !important; }
  .use-bootstrap .overflow-visible {
    overflow: visible !important; }
  .use-bootstrap .overflow-scroll {
    overflow: scroll !important; }
  .use-bootstrap .d-inline {
    display: inline !important; }
  .use-bootstrap .d-inline-block {
    display: inline-block !important; }
  .use-bootstrap .d-block {
    display: block !important; }
  .use-bootstrap .d-grid {
    display: grid !important; }
  .use-bootstrap .d-table {
    display: table !important; }
  .use-bootstrap .d-table-row {
    display: table-row !important; }
  .use-bootstrap .d-table-cell {
    display: table-cell !important; }
  .use-bootstrap .d-flex {
    display: flex !important; }
  .use-bootstrap .d-inline-flex {
    display: inline-flex !important; }
  .use-bootstrap .d-none {
    display: none !important; }
  .use-bootstrap .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
  .use-bootstrap .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }
  .use-bootstrap .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }
  .use-bootstrap .shadow-none {
    box-shadow: none !important; }
  .use-bootstrap .position-static {
    position: static !important; }
  .use-bootstrap .position-relative {
    position: relative !important; }
  .use-bootstrap .position-absolute {
    position: absolute !important; }
  .use-bootstrap .position-fixed {
    position: fixed !important; }
  .use-bootstrap .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important; }
  .use-bootstrap .top-0 {
    top: 0 !important; }
  .use-bootstrap .top-50 {
    top: 50% !important; }
  .use-bootstrap .top-100 {
    top: 100% !important; }
  .use-bootstrap .bottom-0 {
    bottom: 0 !important; }
  .use-bootstrap .bottom-50 {
    bottom: 50% !important; }
  .use-bootstrap .bottom-100 {
    bottom: 100% !important; }
  .use-bootstrap .start-0 {
    left: 0 !important; }
  .use-bootstrap .start-50 {
    left: 50% !important; }
  .use-bootstrap .start-100 {
    left: 100% !important; }
  .use-bootstrap .end-0 {
    right: 0 !important; }
  .use-bootstrap .end-50 {
    right: 50% !important; }
  .use-bootstrap .end-100 {
    right: 100% !important; }
  .use-bootstrap .translate-middle {
    transform: translate(-50%, -50%) !important; }
  .use-bootstrap .translate-middle-x {
    transform: translateX(-50%) !important; }
  .use-bootstrap .translate-middle-y {
    transform: translateY(-50%) !important; }
  .use-bootstrap .border {
    border: 1px solid #dee2e6 !important; }
  .use-bootstrap .border-0 {
    border: 0 !important; }
  .use-bootstrap .border-top {
    border-top: 1px solid #dee2e6 !important; }
  .use-bootstrap .border-top-0 {
    border-top: 0 !important; }
  .use-bootstrap .border-end {
    border-right: 1px solid #dee2e6 !important; }
  .use-bootstrap .border-end-0 {
    border-right: 0 !important; }
  .use-bootstrap .border-bottom {
    border-bottom: 1px solid #dee2e6 !important; }
  .use-bootstrap .border-bottom-0 {
    border-bottom: 0 !important; }
  .use-bootstrap .border-start {
    border-left: 1px solid #dee2e6 !important; }
  .use-bootstrap .border-start-0 {
    border-left: 0 !important; }
  .use-bootstrap .border-primary {
    border-color: #0d6efd !important; }
  .use-bootstrap .border-secondary {
    border-color: #6c757d !important; }
  .use-bootstrap .border-success {
    border-color: #198754 !important; }
  .use-bootstrap .border-info {
    border-color: #0dcaf0 !important; }
  .use-bootstrap .border-warning {
    border-color: #ffc107 !important; }
  .use-bootstrap .border-danger {
    border-color: #dc3545 !important; }
  .use-bootstrap .border-light {
    border-color: #f8f9fa !important; }
  .use-bootstrap .border-dark {
    border-color: #212529 !important; }
  .use-bootstrap .border-white {
    border-color: #fff !important; }
  .use-bootstrap .border-1 {
    border-width: 1px !important; }
  .use-bootstrap .border-2 {
    border-width: 2px !important; }
  .use-bootstrap .border-3 {
    border-width: 3px !important; }
  .use-bootstrap .border-4 {
    border-width: 4px !important; }
  .use-bootstrap .border-5 {
    border-width: 5px !important; }
  .use-bootstrap .w-25 {
    width: 25% !important; }
  .use-bootstrap .w-50 {
    width: 50% !important; }
  .use-bootstrap .w-75 {
    width: 75% !important; }
  .use-bootstrap .w-100 {
    width: 100% !important; }
  .use-bootstrap .w-auto {
    width: auto !important; }
  .use-bootstrap .mw-100 {
    max-width: 100% !important; }
  .use-bootstrap .vw-100 {
    width: 100vw !important; }
  .use-bootstrap .min-vw-100 {
    min-width: 100vw !important; }
  .use-bootstrap .h-25 {
    height: 25% !important; }
  .use-bootstrap .h-50 {
    height: 50% !important; }
  .use-bootstrap .h-75 {
    height: 75% !important; }
  .use-bootstrap .h-100 {
    height: 100% !important; }
  .use-bootstrap .h-auto {
    height: auto !important; }
  .use-bootstrap .mh-100 {
    max-height: 100% !important; }
  .use-bootstrap .vh-100 {
    height: 100vh !important; }
  .use-bootstrap .min-vh-100 {
    min-height: 100vh !important; }
  .use-bootstrap .flex-fill {
    flex: 1 1 auto !important; }
  .use-bootstrap .flex-row {
    flex-direction: row !important; }
  .use-bootstrap .flex-column {
    flex-direction: column !important; }
  .use-bootstrap .flex-row-reverse {
    flex-direction: row-reverse !important; }
  .use-bootstrap .flex-column-reverse {
    flex-direction: column-reverse !important; }
  .use-bootstrap .flex-grow-0 {
    flex-grow: 0 !important; }
  .use-bootstrap .flex-grow-1 {
    flex-grow: 1 !important; }
  .use-bootstrap .flex-shrink-0 {
    flex-shrink: 0 !important; }
  .use-bootstrap .flex-shrink-1 {
    flex-shrink: 1 !important; }
  .use-bootstrap .flex-wrap {
    flex-wrap: wrap !important; }
  .use-bootstrap .flex-nowrap {
    flex-wrap: nowrap !important; }
  .use-bootstrap .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .use-bootstrap .gap-0 {
    gap: 0 !important; }
  .use-bootstrap .gap-1 {
    gap: 0.25rem !important; }
  .use-bootstrap .gap-2 {
    gap: 0.5rem !important; }
  .use-bootstrap .gap-3 {
    gap: 1rem !important; }
  .use-bootstrap .gap-4 {
    gap: 1.5rem !important; }
  .use-bootstrap .gap-5 {
    gap: 3rem !important; }
  .use-bootstrap .justify-content-start {
    justify-content: flex-start !important; }
  .use-bootstrap .justify-content-end {
    justify-content: flex-end !important; }
  .use-bootstrap .justify-content-center {
    justify-content: center !important; }
  .use-bootstrap .justify-content-between {
    justify-content: space-between !important; }
  .use-bootstrap .justify-content-around {
    justify-content: space-around !important; }
  .use-bootstrap .justify-content-evenly {
    justify-content: space-evenly !important; }
  .use-bootstrap .align-items-start {
    align-items: flex-start !important; }
  .use-bootstrap .align-items-end {
    align-items: flex-end !important; }
  .use-bootstrap .align-items-center {
    align-items: center !important; }
  .use-bootstrap .align-items-baseline {
    align-items: baseline !important; }
  .use-bootstrap .align-items-stretch {
    align-items: stretch !important; }
  .use-bootstrap .align-content-start {
    align-content: flex-start !important; }
  .use-bootstrap .align-content-end {
    align-content: flex-end !important; }
  .use-bootstrap .align-content-center {
    align-content: center !important; }
  .use-bootstrap .align-content-between {
    align-content: space-between !important; }
  .use-bootstrap .align-content-around {
    align-content: space-around !important; }
  .use-bootstrap .align-content-stretch {
    align-content: stretch !important; }
  .use-bootstrap .align-self-auto {
    align-self: auto !important; }
  .use-bootstrap .align-self-start {
    align-self: flex-start !important; }
  .use-bootstrap .align-self-end {
    align-self: flex-end !important; }
  .use-bootstrap .align-self-center {
    align-self: center !important; }
  .use-bootstrap .align-self-baseline {
    align-self: baseline !important; }
  .use-bootstrap .align-self-stretch {
    align-self: stretch !important; }
  .use-bootstrap .order-first {
    order: -1 !important; }
  .use-bootstrap .order-0 {
    order: 0 !important; }
  .use-bootstrap .order-1 {
    order: 1 !important; }
  .use-bootstrap .order-2 {
    order: 2 !important; }
  .use-bootstrap .order-3 {
    order: 3 !important; }
  .use-bootstrap .order-4 {
    order: 4 !important; }
  .use-bootstrap .order-5 {
    order: 5 !important; }
  .use-bootstrap .order-last {
    order: 6 !important; }
  .use-bootstrap .m-0 {
    margin: 0 !important; }
  .use-bootstrap .m-1 {
    margin: 0.25rem !important; }
  .use-bootstrap .m-2 {
    margin: 0.5rem !important; }
  .use-bootstrap .m-3 {
    margin: 1rem !important; }
  .use-bootstrap .m-4 {
    margin: 1.5rem !important; }
  .use-bootstrap .m-5 {
    margin: 3rem !important; }
  .use-bootstrap .m-auto {
    margin: auto !important; }
  .use-bootstrap .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .use-bootstrap .mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .use-bootstrap .mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .use-bootstrap .mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .use-bootstrap .mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .use-bootstrap .mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .use-bootstrap .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .use-bootstrap .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .use-bootstrap .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .use-bootstrap .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .use-bootstrap .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .use-bootstrap .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .use-bootstrap .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .use-bootstrap .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .use-bootstrap .mt-0 {
    margin-top: 0 !important; }
  .use-bootstrap .mt-1 {
    margin-top: 0.25rem !important; }
  .use-bootstrap .mt-2 {
    margin-top: 0.5rem !important; }
  .use-bootstrap .mt-3 {
    margin-top: 1rem !important; }
  .use-bootstrap .mt-4 {
    margin-top: 1.5rem !important; }
  .use-bootstrap .mt-5 {
    margin-top: 3rem !important; }
  .use-bootstrap .mt-auto {
    margin-top: auto !important; }
  .use-bootstrap .me-0 {
    margin-right: 0 !important; }
  .use-bootstrap .me-1 {
    margin-right: 0.25rem !important; }
  .use-bootstrap .me-2 {
    margin-right: 0.5rem !important; }
  .use-bootstrap .me-3 {
    margin-right: 1rem !important; }
  .use-bootstrap .me-4 {
    margin-right: 1.5rem !important; }
  .use-bootstrap .me-5 {
    margin-right: 3rem !important; }
  .use-bootstrap .me-auto {
    margin-right: auto !important; }
  .use-bootstrap .mb-0 {
    margin-bottom: 0 !important; }
  .use-bootstrap .mb-1 {
    margin-bottom: 0.25rem !important; }
  .use-bootstrap .mb-2 {
    margin-bottom: 0.5rem !important; }
  .use-bootstrap .mb-3 {
    margin-bottom: 1rem !important; }
  .use-bootstrap .mb-4 {
    margin-bottom: 1.5rem !important; }
  .use-bootstrap .mb-5 {
    margin-bottom: 3rem !important; }
  .use-bootstrap .mb-auto {
    margin-bottom: auto !important; }
  .use-bootstrap .ms-0 {
    margin-left: 0 !important; }
  .use-bootstrap .ms-1 {
    margin-left: 0.25rem !important; }
  .use-bootstrap .ms-2 {
    margin-left: 0.5rem !important; }
  .use-bootstrap .ms-3 {
    margin-left: 1rem !important; }
  .use-bootstrap .ms-4 {
    margin-left: 1.5rem !important; }
  .use-bootstrap .ms-5 {
    margin-left: 3rem !important; }
  .use-bootstrap .ms-auto {
    margin-left: auto !important; }
  .use-bootstrap .p-0 {
    padding: 0 !important; }
  .use-bootstrap .p-1 {
    padding: 0.25rem !important; }
  .use-bootstrap .p-2 {
    padding: 0.5rem !important; }
  .use-bootstrap .p-3 {
    padding: 1rem !important; }
  .use-bootstrap .p-4 {
    padding: 1.5rem !important; }
  .use-bootstrap .p-5 {
    padding: 3rem !important; }
  .use-bootstrap .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .use-bootstrap .px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .use-bootstrap .px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .use-bootstrap .px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .use-bootstrap .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .use-bootstrap .px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .use-bootstrap .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .use-bootstrap .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .use-bootstrap .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .use-bootstrap .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .use-bootstrap .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .use-bootstrap .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .use-bootstrap .pt-0 {
    padding-top: 0 !important; }
  .use-bootstrap .pt-1 {
    padding-top: 0.25rem !important; }
  .use-bootstrap .pt-2 {
    padding-top: 0.5rem !important; }
  .use-bootstrap .pt-3 {
    padding-top: 1rem !important; }
  .use-bootstrap .pt-4 {
    padding-top: 1.5rem !important; }
  .use-bootstrap .pt-5 {
    padding-top: 3rem !important; }
  .use-bootstrap .pe-0 {
    padding-right: 0 !important; }
  .use-bootstrap .pe-1 {
    padding-right: 0.25rem !important; }
  .use-bootstrap .pe-2 {
    padding-right: 0.5rem !important; }
  .use-bootstrap .pe-3 {
    padding-right: 1rem !important; }
  .use-bootstrap .pe-4 {
    padding-right: 1.5rem !important; }
  .use-bootstrap .pe-5 {
    padding-right: 3rem !important; }
  .use-bootstrap .pb-0 {
    padding-bottom: 0 !important; }
  .use-bootstrap .pb-1 {
    padding-bottom: 0.25rem !important; }
  .use-bootstrap .pb-2 {
    padding-bottom: 0.5rem !important; }
  .use-bootstrap .pb-3 {
    padding-bottom: 1rem !important; }
  .use-bootstrap .pb-4 {
    padding-bottom: 1.5rem !important; }
  .use-bootstrap .pb-5 {
    padding-bottom: 3rem !important; }
  .use-bootstrap .ps-0 {
    padding-left: 0 !important; }
  .use-bootstrap .ps-1 {
    padding-left: 0.25rem !important; }
  .use-bootstrap .ps-2 {
    padding-left: 0.5rem !important; }
  .use-bootstrap .ps-3 {
    padding-left: 1rem !important; }
  .use-bootstrap .ps-4 {
    padding-left: 1.5rem !important; }
  .use-bootstrap .ps-5 {
    padding-left: 3rem !important; }
  .use-bootstrap .font-monospace {
    font-family: var(--bs-font-monospace) !important; }
  .use-bootstrap .fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important; }
  .use-bootstrap .fs-2 {
    font-size: calc(1.325rem + 0.9vw) !important; }
  .use-bootstrap .fs-3 {
    font-size: calc(1.3rem + 0.6vw) !important; }
  .use-bootstrap .fs-4 {
    font-size: calc(1.275rem + 0.3vw) !important; }
  .use-bootstrap .fs-5 {
    font-size: 1.25rem !important; }
  .use-bootstrap .fs-6 {
    font-size: 1rem !important; }
  .use-bootstrap .fst-italic {
    font-style: italic !important; }
  .use-bootstrap .fst-normal {
    font-style: normal !important; }
  .use-bootstrap .fw-light {
    font-weight: 300 !important; }
  .use-bootstrap .fw-lighter {
    font-weight: lighter !important; }
  .use-bootstrap .fw-normal {
    font-weight: 400 !important; }
  .use-bootstrap .fw-bold {
    font-weight: 700 !important; }
  .use-bootstrap .fw-bolder {
    font-weight: bolder !important; }
  .use-bootstrap .lh-1 {
    line-height: 1 !important; }
  .use-bootstrap .lh-sm {
    line-height: 1.25 !important; }
  .use-bootstrap .lh-base {
    line-height: 1.5 !important; }
  .use-bootstrap .lh-lg {
    line-height: 2 !important; }
  .use-bootstrap .text-start {
    text-align: left !important; }
  .use-bootstrap .text-end {
    text-align: right !important; }
  .use-bootstrap .text-center {
    text-align: center !important; }
  .use-bootstrap .text-decoration-none {
    text-decoration: none !important; }
  .use-bootstrap .text-decoration-underline {
    text-decoration: underline !important; }
  .use-bootstrap .text-decoration-line-through {
    text-decoration: line-through !important; }
  .use-bootstrap .text-lowercase {
    text-transform: lowercase !important; }
  .use-bootstrap .text-uppercase {
    text-transform: uppercase !important; }
  .use-bootstrap .text-capitalize {
    text-transform: capitalize !important; }
  .use-bootstrap .text-wrap {
    white-space: normal !important; }
  .use-bootstrap .text-nowrap {
    white-space: nowrap !important; }
  .use-bootstrap .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important; }
  .use-bootstrap .text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }
  .use-bootstrap .text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important; }
  .use-bootstrap .text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, 0.5) !important; }
  .use-bootstrap .text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, 0.5) !important; }
  .use-bootstrap .text-reset {
    --bs-text-opacity: 1;
    color: inherit !important; }
  .use-bootstrap .text-opacity-25 {
    --bs-text-opacity: 0.25; }
  .use-bootstrap .text-opacity-50 {
    --bs-text-opacity: 0.5; }
  .use-bootstrap .text-opacity-75 {
    --bs-text-opacity: 0.75; }
  .use-bootstrap .text-opacity-100 {
    --bs-text-opacity: 1; }
  .use-bootstrap .bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }
  .use-bootstrap .bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important; }
  .use-bootstrap .bg-opacity-10 {
    --bs-bg-opacity: 0.1; }
  .use-bootstrap .bg-opacity-25 {
    --bs-bg-opacity: 0.25; }
  .use-bootstrap .bg-opacity-50 {
    --bs-bg-opacity: 0.5; }
  .use-bootstrap .bg-opacity-75 {
    --bs-bg-opacity: 0.75; }
  .use-bootstrap .bg-opacity-100 {
    --bs-bg-opacity: 1; }
  .use-bootstrap .bg-gradient {
    background-image: var(--bs-gradient) !important; }
  .use-bootstrap .user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    user-select: all !important; }
  .use-bootstrap .user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    user-select: auto !important; }
  .use-bootstrap .user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important; }
  .use-bootstrap .pe-none {
    pointer-events: none !important; }
  .use-bootstrap .pe-auto {
    pointer-events: auto !important; }
  .use-bootstrap .rounded {
    border-radius: 0.25rem !important; }
  .use-bootstrap .rounded-0 {
    border-radius: 0 !important; }
  .use-bootstrap .rounded-1 {
    border-radius: 0.2rem !important; }
  .use-bootstrap .rounded-2 {
    border-radius: 0.25rem !important; }
  .use-bootstrap .rounded-3 {
    border-radius: 0.3rem !important; }
  .use-bootstrap .rounded-circle {
    border-radius: 50% !important; }
  .use-bootstrap .rounded-pill {
    border-radius: 50rem !important; }
  .use-bootstrap .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important; }
  .use-bootstrap .rounded-end {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important; }
  .use-bootstrap .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important; }
  .use-bootstrap .rounded-start {
    border-bottom-left-radius: 0.25rem !important;
    border-top-left-radius: 0.25rem !important; }
  .use-bootstrap .visible {
    visibility: visible !important; }
  .use-bootstrap .invisible {
    visibility: hidden !important; }
  @media (min-width: 576px) {
    .use-bootstrap .float-sm-start {
      float: left !important; }
    .use-bootstrap .float-sm-end {
      float: right !important; }
    .use-bootstrap .float-sm-none {
      float: none !important; }
    .use-bootstrap .d-sm-inline {
      display: inline !important; }
    .use-bootstrap .d-sm-inline-block {
      display: inline-block !important; }
    .use-bootstrap .d-sm-block {
      display: block !important; }
    .use-bootstrap .d-sm-grid {
      display: grid !important; }
    .use-bootstrap .d-sm-table {
      display: table !important; }
    .use-bootstrap .d-sm-table-row {
      display: table-row !important; }
    .use-bootstrap .d-sm-table-cell {
      display: table-cell !important; }
    .use-bootstrap .d-sm-flex {
      display: flex !important; }
    .use-bootstrap .d-sm-inline-flex {
      display: inline-flex !important; }
    .use-bootstrap .d-sm-none {
      display: none !important; }
    .use-bootstrap .flex-sm-fill {
      flex: 1 1 auto !important; }
    .use-bootstrap .flex-sm-row {
      flex-direction: row !important; }
    .use-bootstrap .flex-sm-column {
      flex-direction: column !important; }
    .use-bootstrap .flex-sm-row-reverse {
      flex-direction: row-reverse !important; }
    .use-bootstrap .flex-sm-column-reverse {
      flex-direction: column-reverse !important; }
    .use-bootstrap .flex-sm-grow-0 {
      flex-grow: 0 !important; }
    .use-bootstrap .flex-sm-grow-1 {
      flex-grow: 1 !important; }
    .use-bootstrap .flex-sm-shrink-0 {
      flex-shrink: 0 !important; }
    .use-bootstrap .flex-sm-shrink-1 {
      flex-shrink: 1 !important; }
    .use-bootstrap .flex-sm-wrap {
      flex-wrap: wrap !important; }
    .use-bootstrap .flex-sm-nowrap {
      flex-wrap: nowrap !important; }
    .use-bootstrap .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .use-bootstrap .gap-sm-0 {
      gap: 0 !important; }
    .use-bootstrap .gap-sm-1 {
      gap: 0.25rem !important; }
    .use-bootstrap .gap-sm-2 {
      gap: 0.5rem !important; }
    .use-bootstrap .gap-sm-3 {
      gap: 1rem !important; }
    .use-bootstrap .gap-sm-4 {
      gap: 1.5rem !important; }
    .use-bootstrap .gap-sm-5 {
      gap: 3rem !important; }
    .use-bootstrap .justify-content-sm-start {
      justify-content: flex-start !important; }
    .use-bootstrap .justify-content-sm-end {
      justify-content: flex-end !important; }
    .use-bootstrap .justify-content-sm-center {
      justify-content: center !important; }
    .use-bootstrap .justify-content-sm-between {
      justify-content: space-between !important; }
    .use-bootstrap .justify-content-sm-around {
      justify-content: space-around !important; }
    .use-bootstrap .justify-content-sm-evenly {
      justify-content: space-evenly !important; }
    .use-bootstrap .align-items-sm-start {
      align-items: flex-start !important; }
    .use-bootstrap .align-items-sm-end {
      align-items: flex-end !important; }
    .use-bootstrap .align-items-sm-center {
      align-items: center !important; }
    .use-bootstrap .align-items-sm-baseline {
      align-items: baseline !important; }
    .use-bootstrap .align-items-sm-stretch {
      align-items: stretch !important; }
    .use-bootstrap .align-content-sm-start {
      align-content: flex-start !important; }
    .use-bootstrap .align-content-sm-end {
      align-content: flex-end !important; }
    .use-bootstrap .align-content-sm-center {
      align-content: center !important; }
    .use-bootstrap .align-content-sm-between {
      align-content: space-between !important; }
    .use-bootstrap .align-content-sm-around {
      align-content: space-around !important; }
    .use-bootstrap .align-content-sm-stretch {
      align-content: stretch !important; }
    .use-bootstrap .align-self-sm-auto {
      align-self: auto !important; }
    .use-bootstrap .align-self-sm-start {
      align-self: flex-start !important; }
    .use-bootstrap .align-self-sm-end {
      align-self: flex-end !important; }
    .use-bootstrap .align-self-sm-center {
      align-self: center !important; }
    .use-bootstrap .align-self-sm-baseline {
      align-self: baseline !important; }
    .use-bootstrap .align-self-sm-stretch {
      align-self: stretch !important; }
    .use-bootstrap .order-sm-first {
      order: -1 !important; }
    .use-bootstrap .order-sm-0 {
      order: 0 !important; }
    .use-bootstrap .order-sm-1 {
      order: 1 !important; }
    .use-bootstrap .order-sm-2 {
      order: 2 !important; }
    .use-bootstrap .order-sm-3 {
      order: 3 !important; }
    .use-bootstrap .order-sm-4 {
      order: 4 !important; }
    .use-bootstrap .order-sm-5 {
      order: 5 !important; }
    .use-bootstrap .order-sm-last {
      order: 6 !important; }
    .use-bootstrap .m-sm-0 {
      margin: 0 !important; }
    .use-bootstrap .m-sm-1 {
      margin: 0.25rem !important; }
    .use-bootstrap .m-sm-2 {
      margin: 0.5rem !important; }
    .use-bootstrap .m-sm-3 {
      margin: 1rem !important; }
    .use-bootstrap .m-sm-4 {
      margin: 1.5rem !important; }
    .use-bootstrap .m-sm-5 {
      margin: 3rem !important; }
    .use-bootstrap .m-sm-auto {
      margin: auto !important; }
    .use-bootstrap .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .use-bootstrap .mx-sm-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important; }
    .use-bootstrap .mx-sm-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .use-bootstrap .mx-sm-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .use-bootstrap .mx-sm-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; }
    .use-bootstrap .mx-sm-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .use-bootstrap .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .use-bootstrap .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .use-bootstrap .my-sm-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important; }
    .use-bootstrap .my-sm-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .use-bootstrap .my-sm-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .use-bootstrap .my-sm-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; }
    .use-bootstrap .my-sm-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .use-bootstrap .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .use-bootstrap .mt-sm-0 {
      margin-top: 0 !important; }
    .use-bootstrap .mt-sm-1 {
      margin-top: 0.25rem !important; }
    .use-bootstrap .mt-sm-2 {
      margin-top: 0.5rem !important; }
    .use-bootstrap .mt-sm-3 {
      margin-top: 1rem !important; }
    .use-bootstrap .mt-sm-4 {
      margin-top: 1.5rem !important; }
    .use-bootstrap .mt-sm-5 {
      margin-top: 3rem !important; }
    .use-bootstrap .mt-sm-auto {
      margin-top: auto !important; }
    .use-bootstrap .me-sm-0 {
      margin-right: 0 !important; }
    .use-bootstrap .me-sm-1 {
      margin-right: 0.25rem !important; }
    .use-bootstrap .me-sm-2 {
      margin-right: 0.5rem !important; }
    .use-bootstrap .me-sm-3 {
      margin-right: 1rem !important; }
    .use-bootstrap .me-sm-4 {
      margin-right: 1.5rem !important; }
    .use-bootstrap .me-sm-5 {
      margin-right: 3rem !important; }
    .use-bootstrap .me-sm-auto {
      margin-right: auto !important; }
    .use-bootstrap .mb-sm-0 {
      margin-bottom: 0 !important; }
    .use-bootstrap .mb-sm-1 {
      margin-bottom: 0.25rem !important; }
    .use-bootstrap .mb-sm-2 {
      margin-bottom: 0.5rem !important; }
    .use-bootstrap .mb-sm-3 {
      margin-bottom: 1rem !important; }
    .use-bootstrap .mb-sm-4 {
      margin-bottom: 1.5rem !important; }
    .use-bootstrap .mb-sm-5 {
      margin-bottom: 3rem !important; }
    .use-bootstrap .mb-sm-auto {
      margin-bottom: auto !important; }
    .use-bootstrap .ms-sm-0 {
      margin-left: 0 !important; }
    .use-bootstrap .ms-sm-1 {
      margin-left: 0.25rem !important; }
    .use-bootstrap .ms-sm-2 {
      margin-left: 0.5rem !important; }
    .use-bootstrap .ms-sm-3 {
      margin-left: 1rem !important; }
    .use-bootstrap .ms-sm-4 {
      margin-left: 1.5rem !important; }
    .use-bootstrap .ms-sm-5 {
      margin-left: 3rem !important; }
    .use-bootstrap .ms-sm-auto {
      margin-left: auto !important; }
    .use-bootstrap .p-sm-0 {
      padding: 0 !important; }
    .use-bootstrap .p-sm-1 {
      padding: 0.25rem !important; }
    .use-bootstrap .p-sm-2 {
      padding: 0.5rem !important; }
    .use-bootstrap .p-sm-3 {
      padding: 1rem !important; }
    .use-bootstrap .p-sm-4 {
      padding: 1.5rem !important; }
    .use-bootstrap .p-sm-5 {
      padding: 3rem !important; }
    .use-bootstrap .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .use-bootstrap .px-sm-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
    .use-bootstrap .px-sm-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .use-bootstrap .px-sm-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .use-bootstrap .px-sm-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; }
    .use-bootstrap .px-sm-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .use-bootstrap .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .use-bootstrap .py-sm-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important; }
    .use-bootstrap .py-sm-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .use-bootstrap .py-sm-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .use-bootstrap .py-sm-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; }
    .use-bootstrap .py-sm-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .use-bootstrap .pt-sm-0 {
      padding-top: 0 !important; }
    .use-bootstrap .pt-sm-1 {
      padding-top: 0.25rem !important; }
    .use-bootstrap .pt-sm-2 {
      padding-top: 0.5rem !important; }
    .use-bootstrap .pt-sm-3 {
      padding-top: 1rem !important; }
    .use-bootstrap .pt-sm-4 {
      padding-top: 1.5rem !important; }
    .use-bootstrap .pt-sm-5 {
      padding-top: 3rem !important; }
    .use-bootstrap .pe-sm-0 {
      padding-right: 0 !important; }
    .use-bootstrap .pe-sm-1 {
      padding-right: 0.25rem !important; }
    .use-bootstrap .pe-sm-2 {
      padding-right: 0.5rem !important; }
    .use-bootstrap .pe-sm-3 {
      padding-right: 1rem !important; }
    .use-bootstrap .pe-sm-4 {
      padding-right: 1.5rem !important; }
    .use-bootstrap .pe-sm-5 {
      padding-right: 3rem !important; }
    .use-bootstrap .pb-sm-0 {
      padding-bottom: 0 !important; }
    .use-bootstrap .pb-sm-1 {
      padding-bottom: 0.25rem !important; }
    .use-bootstrap .pb-sm-2 {
      padding-bottom: 0.5rem !important; }
    .use-bootstrap .pb-sm-3 {
      padding-bottom: 1rem !important; }
    .use-bootstrap .pb-sm-4 {
      padding-bottom: 1.5rem !important; }
    .use-bootstrap .pb-sm-5 {
      padding-bottom: 3rem !important; }
    .use-bootstrap .ps-sm-0 {
      padding-left: 0 !important; }
    .use-bootstrap .ps-sm-1 {
      padding-left: 0.25rem !important; }
    .use-bootstrap .ps-sm-2 {
      padding-left: 0.5rem !important; }
    .use-bootstrap .ps-sm-3 {
      padding-left: 1rem !important; }
    .use-bootstrap .ps-sm-4 {
      padding-left: 1.5rem !important; }
    .use-bootstrap .ps-sm-5 {
      padding-left: 3rem !important; }
    .use-bootstrap .text-sm-start {
      text-align: left !important; }
    .use-bootstrap .text-sm-end {
      text-align: right !important; }
    .use-bootstrap .text-sm-center {
      text-align: center !important; } }
  @media (min-width: 768px) {
    .use-bootstrap .float-md-start {
      float: left !important; }
    .use-bootstrap .float-md-end {
      float: right !important; }
    .use-bootstrap .float-md-none {
      float: none !important; }
    .use-bootstrap .d-md-inline {
      display: inline !important; }
    .use-bootstrap .d-md-inline-block {
      display: inline-block !important; }
    .use-bootstrap .d-md-block {
      display: block !important; }
    .use-bootstrap .d-md-grid {
      display: grid !important; }
    .use-bootstrap .d-md-table {
      display: table !important; }
    .use-bootstrap .d-md-table-row {
      display: table-row !important; }
    .use-bootstrap .d-md-table-cell {
      display: table-cell !important; }
    .use-bootstrap .d-md-flex {
      display: flex !important; }
    .use-bootstrap .d-md-inline-flex {
      display: inline-flex !important; }
    .use-bootstrap .d-md-none {
      display: none !important; }
    .use-bootstrap .flex-md-fill {
      flex: 1 1 auto !important; }
    .use-bootstrap .flex-md-row {
      flex-direction: row !important; }
    .use-bootstrap .flex-md-column {
      flex-direction: column !important; }
    .use-bootstrap .flex-md-row-reverse {
      flex-direction: row-reverse !important; }
    .use-bootstrap .flex-md-column-reverse {
      flex-direction: column-reverse !important; }
    .use-bootstrap .flex-md-grow-0 {
      flex-grow: 0 !important; }
    .use-bootstrap .flex-md-grow-1 {
      flex-grow: 1 !important; }
    .use-bootstrap .flex-md-shrink-0 {
      flex-shrink: 0 !important; }
    .use-bootstrap .flex-md-shrink-1 {
      flex-shrink: 1 !important; }
    .use-bootstrap .flex-md-wrap {
      flex-wrap: wrap !important; }
    .use-bootstrap .flex-md-nowrap {
      flex-wrap: nowrap !important; }
    .use-bootstrap .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .use-bootstrap .gap-md-0 {
      gap: 0 !important; }
    .use-bootstrap .gap-md-1 {
      gap: 0.25rem !important; }
    .use-bootstrap .gap-md-2 {
      gap: 0.5rem !important; }
    .use-bootstrap .gap-md-3 {
      gap: 1rem !important; }
    .use-bootstrap .gap-md-4 {
      gap: 1.5rem !important; }
    .use-bootstrap .gap-md-5 {
      gap: 3rem !important; }
    .use-bootstrap .justify-content-md-start {
      justify-content: flex-start !important; }
    .use-bootstrap .justify-content-md-end {
      justify-content: flex-end !important; }
    .use-bootstrap .justify-content-md-center {
      justify-content: center !important; }
    .use-bootstrap .justify-content-md-between {
      justify-content: space-between !important; }
    .use-bootstrap .justify-content-md-around {
      justify-content: space-around !important; }
    .use-bootstrap .justify-content-md-evenly {
      justify-content: space-evenly !important; }
    .use-bootstrap .align-items-md-start {
      align-items: flex-start !important; }
    .use-bootstrap .align-items-md-end {
      align-items: flex-end !important; }
    .use-bootstrap .align-items-md-center {
      align-items: center !important; }
    .use-bootstrap .align-items-md-baseline {
      align-items: baseline !important; }
    .use-bootstrap .align-items-md-stretch {
      align-items: stretch !important; }
    .use-bootstrap .align-content-md-start {
      align-content: flex-start !important; }
    .use-bootstrap .align-content-md-end {
      align-content: flex-end !important; }
    .use-bootstrap .align-content-md-center {
      align-content: center !important; }
    .use-bootstrap .align-content-md-between {
      align-content: space-between !important; }
    .use-bootstrap .align-content-md-around {
      align-content: space-around !important; }
    .use-bootstrap .align-content-md-stretch {
      align-content: stretch !important; }
    .use-bootstrap .align-self-md-auto {
      align-self: auto !important; }
    .use-bootstrap .align-self-md-start {
      align-self: flex-start !important; }
    .use-bootstrap .align-self-md-end {
      align-self: flex-end !important; }
    .use-bootstrap .align-self-md-center {
      align-self: center !important; }
    .use-bootstrap .align-self-md-baseline {
      align-self: baseline !important; }
    .use-bootstrap .align-self-md-stretch {
      align-self: stretch !important; }
    .use-bootstrap .order-md-first {
      order: -1 !important; }
    .use-bootstrap .order-md-0 {
      order: 0 !important; }
    .use-bootstrap .order-md-1 {
      order: 1 !important; }
    .use-bootstrap .order-md-2 {
      order: 2 !important; }
    .use-bootstrap .order-md-3 {
      order: 3 !important; }
    .use-bootstrap .order-md-4 {
      order: 4 !important; }
    .use-bootstrap .order-md-5 {
      order: 5 !important; }
    .use-bootstrap .order-md-last {
      order: 6 !important; }
    .use-bootstrap .m-md-0 {
      margin: 0 !important; }
    .use-bootstrap .m-md-1 {
      margin: 0.25rem !important; }
    .use-bootstrap .m-md-2 {
      margin: 0.5rem !important; }
    .use-bootstrap .m-md-3 {
      margin: 1rem !important; }
    .use-bootstrap .m-md-4 {
      margin: 1.5rem !important; }
    .use-bootstrap .m-md-5 {
      margin: 3rem !important; }
    .use-bootstrap .m-md-auto {
      margin: auto !important; }
    .use-bootstrap .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .use-bootstrap .mx-md-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important; }
    .use-bootstrap .mx-md-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .use-bootstrap .mx-md-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .use-bootstrap .mx-md-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; }
    .use-bootstrap .mx-md-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .use-bootstrap .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .use-bootstrap .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .use-bootstrap .my-md-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important; }
    .use-bootstrap .my-md-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .use-bootstrap .my-md-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .use-bootstrap .my-md-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; }
    .use-bootstrap .my-md-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .use-bootstrap .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .use-bootstrap .mt-md-0 {
      margin-top: 0 !important; }
    .use-bootstrap .mt-md-1 {
      margin-top: 0.25rem !important; }
    .use-bootstrap .mt-md-2 {
      margin-top: 0.5rem !important; }
    .use-bootstrap .mt-md-3 {
      margin-top: 1rem !important; }
    .use-bootstrap .mt-md-4 {
      margin-top: 1.5rem !important; }
    .use-bootstrap .mt-md-5 {
      margin-top: 3rem !important; }
    .use-bootstrap .mt-md-auto {
      margin-top: auto !important; }
    .use-bootstrap .me-md-0 {
      margin-right: 0 !important; }
    .use-bootstrap .me-md-1 {
      margin-right: 0.25rem !important; }
    .use-bootstrap .me-md-2 {
      margin-right: 0.5rem !important; }
    .use-bootstrap .me-md-3 {
      margin-right: 1rem !important; }
    .use-bootstrap .me-md-4 {
      margin-right: 1.5rem !important; }
    .use-bootstrap .me-md-5 {
      margin-right: 3rem !important; }
    .use-bootstrap .me-md-auto {
      margin-right: auto !important; }
    .use-bootstrap .mb-md-0 {
      margin-bottom: 0 !important; }
    .use-bootstrap .mb-md-1 {
      margin-bottom: 0.25rem !important; }
    .use-bootstrap .mb-md-2 {
      margin-bottom: 0.5rem !important; }
    .use-bootstrap .mb-md-3 {
      margin-bottom: 1rem !important; }
    .use-bootstrap .mb-md-4 {
      margin-bottom: 1.5rem !important; }
    .use-bootstrap .mb-md-5 {
      margin-bottom: 3rem !important; }
    .use-bootstrap .mb-md-auto {
      margin-bottom: auto !important; }
    .use-bootstrap .ms-md-0 {
      margin-left: 0 !important; }
    .use-bootstrap .ms-md-1 {
      margin-left: 0.25rem !important; }
    .use-bootstrap .ms-md-2 {
      margin-left: 0.5rem !important; }
    .use-bootstrap .ms-md-3 {
      margin-left: 1rem !important; }
    .use-bootstrap .ms-md-4 {
      margin-left: 1.5rem !important; }
    .use-bootstrap .ms-md-5 {
      margin-left: 3rem !important; }
    .use-bootstrap .ms-md-auto {
      margin-left: auto !important; }
    .use-bootstrap .p-md-0 {
      padding: 0 !important; }
    .use-bootstrap .p-md-1 {
      padding: 0.25rem !important; }
    .use-bootstrap .p-md-2 {
      padding: 0.5rem !important; }
    .use-bootstrap .p-md-3 {
      padding: 1rem !important; }
    .use-bootstrap .p-md-4 {
      padding: 1.5rem !important; }
    .use-bootstrap .p-md-5 {
      padding: 3rem !important; }
    .use-bootstrap .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .use-bootstrap .px-md-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
    .use-bootstrap .px-md-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .use-bootstrap .px-md-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .use-bootstrap .px-md-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; }
    .use-bootstrap .px-md-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .use-bootstrap .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .use-bootstrap .py-md-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important; }
    .use-bootstrap .py-md-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .use-bootstrap .py-md-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .use-bootstrap .py-md-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; }
    .use-bootstrap .py-md-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .use-bootstrap .pt-md-0 {
      padding-top: 0 !important; }
    .use-bootstrap .pt-md-1 {
      padding-top: 0.25rem !important; }
    .use-bootstrap .pt-md-2 {
      padding-top: 0.5rem !important; }
    .use-bootstrap .pt-md-3 {
      padding-top: 1rem !important; }
    .use-bootstrap .pt-md-4 {
      padding-top: 1.5rem !important; }
    .use-bootstrap .pt-md-5 {
      padding-top: 3rem !important; }
    .use-bootstrap .pe-md-0 {
      padding-right: 0 !important; }
    .use-bootstrap .pe-md-1 {
      padding-right: 0.25rem !important; }
    .use-bootstrap .pe-md-2 {
      padding-right: 0.5rem !important; }
    .use-bootstrap .pe-md-3 {
      padding-right: 1rem !important; }
    .use-bootstrap .pe-md-4 {
      padding-right: 1.5rem !important; }
    .use-bootstrap .pe-md-5 {
      padding-right: 3rem !important; }
    .use-bootstrap .pb-md-0 {
      padding-bottom: 0 !important; }
    .use-bootstrap .pb-md-1 {
      padding-bottom: 0.25rem !important; }
    .use-bootstrap .pb-md-2 {
      padding-bottom: 0.5rem !important; }
    .use-bootstrap .pb-md-3 {
      padding-bottom: 1rem !important; }
    .use-bootstrap .pb-md-4 {
      padding-bottom: 1.5rem !important; }
    .use-bootstrap .pb-md-5 {
      padding-bottom: 3rem !important; }
    .use-bootstrap .ps-md-0 {
      padding-left: 0 !important; }
    .use-bootstrap .ps-md-1 {
      padding-left: 0.25rem !important; }
    .use-bootstrap .ps-md-2 {
      padding-left: 0.5rem !important; }
    .use-bootstrap .ps-md-3 {
      padding-left: 1rem !important; }
    .use-bootstrap .ps-md-4 {
      padding-left: 1.5rem !important; }
    .use-bootstrap .ps-md-5 {
      padding-left: 3rem !important; }
    .use-bootstrap .text-md-start {
      text-align: left !important; }
    .use-bootstrap .text-md-end {
      text-align: right !important; }
    .use-bootstrap .text-md-center {
      text-align: center !important; } }
  @media (min-width: 992px) {
    .use-bootstrap .float-lg-start {
      float: left !important; }
    .use-bootstrap .float-lg-end {
      float: right !important; }
    .use-bootstrap .float-lg-none {
      float: none !important; }
    .use-bootstrap .d-lg-inline {
      display: inline !important; }
    .use-bootstrap .d-lg-inline-block {
      display: inline-block !important; }
    .use-bootstrap .d-lg-block {
      display: block !important; }
    .use-bootstrap .d-lg-grid {
      display: grid !important; }
    .use-bootstrap .d-lg-table {
      display: table !important; }
    .use-bootstrap .d-lg-table-row {
      display: table-row !important; }
    .use-bootstrap .d-lg-table-cell {
      display: table-cell !important; }
    .use-bootstrap .d-lg-flex {
      display: flex !important; }
    .use-bootstrap .d-lg-inline-flex {
      display: inline-flex !important; }
    .use-bootstrap .d-lg-none {
      display: none !important; }
    .use-bootstrap .flex-lg-fill {
      flex: 1 1 auto !important; }
    .use-bootstrap .flex-lg-row {
      flex-direction: row !important; }
    .use-bootstrap .flex-lg-column {
      flex-direction: column !important; }
    .use-bootstrap .flex-lg-row-reverse {
      flex-direction: row-reverse !important; }
    .use-bootstrap .flex-lg-column-reverse {
      flex-direction: column-reverse !important; }
    .use-bootstrap .flex-lg-grow-0 {
      flex-grow: 0 !important; }
    .use-bootstrap .flex-lg-grow-1 {
      flex-grow: 1 !important; }
    .use-bootstrap .flex-lg-shrink-0 {
      flex-shrink: 0 !important; }
    .use-bootstrap .flex-lg-shrink-1 {
      flex-shrink: 1 !important; }
    .use-bootstrap .flex-lg-wrap {
      flex-wrap: wrap !important; }
    .use-bootstrap .flex-lg-nowrap {
      flex-wrap: nowrap !important; }
    .use-bootstrap .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .use-bootstrap .gap-lg-0 {
      gap: 0 !important; }
    .use-bootstrap .gap-lg-1 {
      gap: 0.25rem !important; }
    .use-bootstrap .gap-lg-2 {
      gap: 0.5rem !important; }
    .use-bootstrap .gap-lg-3 {
      gap: 1rem !important; }
    .use-bootstrap .gap-lg-4 {
      gap: 1.5rem !important; }
    .use-bootstrap .gap-lg-5 {
      gap: 3rem !important; }
    .use-bootstrap .justify-content-lg-start {
      justify-content: flex-start !important; }
    .use-bootstrap .justify-content-lg-end {
      justify-content: flex-end !important; }
    .use-bootstrap .justify-content-lg-center {
      justify-content: center !important; }
    .use-bootstrap .justify-content-lg-between {
      justify-content: space-between !important; }
    .use-bootstrap .justify-content-lg-around {
      justify-content: space-around !important; }
    .use-bootstrap .justify-content-lg-evenly {
      justify-content: space-evenly !important; }
    .use-bootstrap .align-items-lg-start {
      align-items: flex-start !important; }
    .use-bootstrap .align-items-lg-end {
      align-items: flex-end !important; }
    .use-bootstrap .align-items-lg-center {
      align-items: center !important; }
    .use-bootstrap .align-items-lg-baseline {
      align-items: baseline !important; }
    .use-bootstrap .align-items-lg-stretch {
      align-items: stretch !important; }
    .use-bootstrap .align-content-lg-start {
      align-content: flex-start !important; }
    .use-bootstrap .align-content-lg-end {
      align-content: flex-end !important; }
    .use-bootstrap .align-content-lg-center {
      align-content: center !important; }
    .use-bootstrap .align-content-lg-between {
      align-content: space-between !important; }
    .use-bootstrap .align-content-lg-around {
      align-content: space-around !important; }
    .use-bootstrap .align-content-lg-stretch {
      align-content: stretch !important; }
    .use-bootstrap .align-self-lg-auto {
      align-self: auto !important; }
    .use-bootstrap .align-self-lg-start {
      align-self: flex-start !important; }
    .use-bootstrap .align-self-lg-end {
      align-self: flex-end !important; }
    .use-bootstrap .align-self-lg-center {
      align-self: center !important; }
    .use-bootstrap .align-self-lg-baseline {
      align-self: baseline !important; }
    .use-bootstrap .align-self-lg-stretch {
      align-self: stretch !important; }
    .use-bootstrap .order-lg-first {
      order: -1 !important; }
    .use-bootstrap .order-lg-0 {
      order: 0 !important; }
    .use-bootstrap .order-lg-1 {
      order: 1 !important; }
    .use-bootstrap .order-lg-2 {
      order: 2 !important; }
    .use-bootstrap .order-lg-3 {
      order: 3 !important; }
    .use-bootstrap .order-lg-4 {
      order: 4 !important; }
    .use-bootstrap .order-lg-5 {
      order: 5 !important; }
    .use-bootstrap .order-lg-last {
      order: 6 !important; }
    .use-bootstrap .m-lg-0 {
      margin: 0 !important; }
    .use-bootstrap .m-lg-1 {
      margin: 0.25rem !important; }
    .use-bootstrap .m-lg-2 {
      margin: 0.5rem !important; }
    .use-bootstrap .m-lg-3 {
      margin: 1rem !important; }
    .use-bootstrap .m-lg-4 {
      margin: 1.5rem !important; }
    .use-bootstrap .m-lg-5 {
      margin: 3rem !important; }
    .use-bootstrap .m-lg-auto {
      margin: auto !important; }
    .use-bootstrap .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .use-bootstrap .mx-lg-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important; }
    .use-bootstrap .mx-lg-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .use-bootstrap .mx-lg-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .use-bootstrap .mx-lg-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; }
    .use-bootstrap .mx-lg-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .use-bootstrap .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .use-bootstrap .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .use-bootstrap .my-lg-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important; }
    .use-bootstrap .my-lg-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .use-bootstrap .my-lg-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .use-bootstrap .my-lg-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; }
    .use-bootstrap .my-lg-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .use-bootstrap .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .use-bootstrap .mt-lg-0 {
      margin-top: 0 !important; }
    .use-bootstrap .mt-lg-1 {
      margin-top: 0.25rem !important; }
    .use-bootstrap .mt-lg-2 {
      margin-top: 0.5rem !important; }
    .use-bootstrap .mt-lg-3 {
      margin-top: 1rem !important; }
    .use-bootstrap .mt-lg-4 {
      margin-top: 1.5rem !important; }
    .use-bootstrap .mt-lg-5 {
      margin-top: 3rem !important; }
    .use-bootstrap .mt-lg-auto {
      margin-top: auto !important; }
    .use-bootstrap .me-lg-0 {
      margin-right: 0 !important; }
    .use-bootstrap .me-lg-1 {
      margin-right: 0.25rem !important; }
    .use-bootstrap .me-lg-2 {
      margin-right: 0.5rem !important; }
    .use-bootstrap .me-lg-3 {
      margin-right: 1rem !important; }
    .use-bootstrap .me-lg-4 {
      margin-right: 1.5rem !important; }
    .use-bootstrap .me-lg-5 {
      margin-right: 3rem !important; }
    .use-bootstrap .me-lg-auto {
      margin-right: auto !important; }
    .use-bootstrap .mb-lg-0 {
      margin-bottom: 0 !important; }
    .use-bootstrap .mb-lg-1 {
      margin-bottom: 0.25rem !important; }
    .use-bootstrap .mb-lg-2 {
      margin-bottom: 0.5rem !important; }
    .use-bootstrap .mb-lg-3 {
      margin-bottom: 1rem !important; }
    .use-bootstrap .mb-lg-4 {
      margin-bottom: 1.5rem !important; }
    .use-bootstrap .mb-lg-5 {
      margin-bottom: 3rem !important; }
    .use-bootstrap .mb-lg-auto {
      margin-bottom: auto !important; }
    .use-bootstrap .ms-lg-0 {
      margin-left: 0 !important; }
    .use-bootstrap .ms-lg-1 {
      margin-left: 0.25rem !important; }
    .use-bootstrap .ms-lg-2 {
      margin-left: 0.5rem !important; }
    .use-bootstrap .ms-lg-3 {
      margin-left: 1rem !important; }
    .use-bootstrap .ms-lg-4 {
      margin-left: 1.5rem !important; }
    .use-bootstrap .ms-lg-5 {
      margin-left: 3rem !important; }
    .use-bootstrap .ms-lg-auto {
      margin-left: auto !important; }
    .use-bootstrap .p-lg-0 {
      padding: 0 !important; }
    .use-bootstrap .p-lg-1 {
      padding: 0.25rem !important; }
    .use-bootstrap .p-lg-2 {
      padding: 0.5rem !important; }
    .use-bootstrap .p-lg-3 {
      padding: 1rem !important; }
    .use-bootstrap .p-lg-4 {
      padding: 1.5rem !important; }
    .use-bootstrap .p-lg-5 {
      padding: 3rem !important; }
    .use-bootstrap .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .use-bootstrap .px-lg-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
    .use-bootstrap .px-lg-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .use-bootstrap .px-lg-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .use-bootstrap .px-lg-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; }
    .use-bootstrap .px-lg-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .use-bootstrap .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .use-bootstrap .py-lg-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important; }
    .use-bootstrap .py-lg-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .use-bootstrap .py-lg-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .use-bootstrap .py-lg-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; }
    .use-bootstrap .py-lg-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .use-bootstrap .pt-lg-0 {
      padding-top: 0 !important; }
    .use-bootstrap .pt-lg-1 {
      padding-top: 0.25rem !important; }
    .use-bootstrap .pt-lg-2 {
      padding-top: 0.5rem !important; }
    .use-bootstrap .pt-lg-3 {
      padding-top: 1rem !important; }
    .use-bootstrap .pt-lg-4 {
      padding-top: 1.5rem !important; }
    .use-bootstrap .pt-lg-5 {
      padding-top: 3rem !important; }
    .use-bootstrap .pe-lg-0 {
      padding-right: 0 !important; }
    .use-bootstrap .pe-lg-1 {
      padding-right: 0.25rem !important; }
    .use-bootstrap .pe-lg-2 {
      padding-right: 0.5rem !important; }
    .use-bootstrap .pe-lg-3 {
      padding-right: 1rem !important; }
    .use-bootstrap .pe-lg-4 {
      padding-right: 1.5rem !important; }
    .use-bootstrap .pe-lg-5 {
      padding-right: 3rem !important; }
    .use-bootstrap .pb-lg-0 {
      padding-bottom: 0 !important; }
    .use-bootstrap .pb-lg-1 {
      padding-bottom: 0.25rem !important; }
    .use-bootstrap .pb-lg-2 {
      padding-bottom: 0.5rem !important; }
    .use-bootstrap .pb-lg-3 {
      padding-bottom: 1rem !important; }
    .use-bootstrap .pb-lg-4 {
      padding-bottom: 1.5rem !important; }
    .use-bootstrap .pb-lg-5 {
      padding-bottom: 3rem !important; }
    .use-bootstrap .ps-lg-0 {
      padding-left: 0 !important; }
    .use-bootstrap .ps-lg-1 {
      padding-left: 0.25rem !important; }
    .use-bootstrap .ps-lg-2 {
      padding-left: 0.5rem !important; }
    .use-bootstrap .ps-lg-3 {
      padding-left: 1rem !important; }
    .use-bootstrap .ps-lg-4 {
      padding-left: 1.5rem !important; }
    .use-bootstrap .ps-lg-5 {
      padding-left: 3rem !important; }
    .use-bootstrap .text-lg-start {
      text-align: left !important; }
    .use-bootstrap .text-lg-end {
      text-align: right !important; }
    .use-bootstrap .text-lg-center {
      text-align: center !important; } }
  @media (min-width: 1200px) {
    .use-bootstrap .float-xl-start {
      float: left !important; }
    .use-bootstrap .float-xl-end {
      float: right !important; }
    .use-bootstrap .float-xl-none {
      float: none !important; }
    .use-bootstrap .d-xl-inline {
      display: inline !important; }
    .use-bootstrap .d-xl-inline-block {
      display: inline-block !important; }
    .use-bootstrap .d-xl-block {
      display: block !important; }
    .use-bootstrap .d-xl-grid {
      display: grid !important; }
    .use-bootstrap .d-xl-table {
      display: table !important; }
    .use-bootstrap .d-xl-table-row {
      display: table-row !important; }
    .use-bootstrap .d-xl-table-cell {
      display: table-cell !important; }
    .use-bootstrap .d-xl-flex {
      display: flex !important; }
    .use-bootstrap .d-xl-inline-flex {
      display: inline-flex !important; }
    .use-bootstrap .d-xl-none {
      display: none !important; }
    .use-bootstrap .flex-xl-fill {
      flex: 1 1 auto !important; }
    .use-bootstrap .flex-xl-row {
      flex-direction: row !important; }
    .use-bootstrap .flex-xl-column {
      flex-direction: column !important; }
    .use-bootstrap .flex-xl-row-reverse {
      flex-direction: row-reverse !important; }
    .use-bootstrap .flex-xl-column-reverse {
      flex-direction: column-reverse !important; }
    .use-bootstrap .flex-xl-grow-0 {
      flex-grow: 0 !important; }
    .use-bootstrap .flex-xl-grow-1 {
      flex-grow: 1 !important; }
    .use-bootstrap .flex-xl-shrink-0 {
      flex-shrink: 0 !important; }
    .use-bootstrap .flex-xl-shrink-1 {
      flex-shrink: 1 !important; }
    .use-bootstrap .flex-xl-wrap {
      flex-wrap: wrap !important; }
    .use-bootstrap .flex-xl-nowrap {
      flex-wrap: nowrap !important; }
    .use-bootstrap .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .use-bootstrap .gap-xl-0 {
      gap: 0 !important; }
    .use-bootstrap .gap-xl-1 {
      gap: 0.25rem !important; }
    .use-bootstrap .gap-xl-2 {
      gap: 0.5rem !important; }
    .use-bootstrap .gap-xl-3 {
      gap: 1rem !important; }
    .use-bootstrap .gap-xl-4 {
      gap: 1.5rem !important; }
    .use-bootstrap .gap-xl-5 {
      gap: 3rem !important; }
    .use-bootstrap .justify-content-xl-start {
      justify-content: flex-start !important; }
    .use-bootstrap .justify-content-xl-end {
      justify-content: flex-end !important; }
    .use-bootstrap .justify-content-xl-center {
      justify-content: center !important; }
    .use-bootstrap .justify-content-xl-between {
      justify-content: space-between !important; }
    .use-bootstrap .justify-content-xl-around {
      justify-content: space-around !important; }
    .use-bootstrap .justify-content-xl-evenly {
      justify-content: space-evenly !important; }
    .use-bootstrap .align-items-xl-start {
      align-items: flex-start !important; }
    .use-bootstrap .align-items-xl-end {
      align-items: flex-end !important; }
    .use-bootstrap .align-items-xl-center {
      align-items: center !important; }
    .use-bootstrap .align-items-xl-baseline {
      align-items: baseline !important; }
    .use-bootstrap .align-items-xl-stretch {
      align-items: stretch !important; }
    .use-bootstrap .align-content-xl-start {
      align-content: flex-start !important; }
    .use-bootstrap .align-content-xl-end {
      align-content: flex-end !important; }
    .use-bootstrap .align-content-xl-center {
      align-content: center !important; }
    .use-bootstrap .align-content-xl-between {
      align-content: space-between !important; }
    .use-bootstrap .align-content-xl-around {
      align-content: space-around !important; }
    .use-bootstrap .align-content-xl-stretch {
      align-content: stretch !important; }
    .use-bootstrap .align-self-xl-auto {
      align-self: auto !important; }
    .use-bootstrap .align-self-xl-start {
      align-self: flex-start !important; }
    .use-bootstrap .align-self-xl-end {
      align-self: flex-end !important; }
    .use-bootstrap .align-self-xl-center {
      align-self: center !important; }
    .use-bootstrap .align-self-xl-baseline {
      align-self: baseline !important; }
    .use-bootstrap .align-self-xl-stretch {
      align-self: stretch !important; }
    .use-bootstrap .order-xl-first {
      order: -1 !important; }
    .use-bootstrap .order-xl-0 {
      order: 0 !important; }
    .use-bootstrap .order-xl-1 {
      order: 1 !important; }
    .use-bootstrap .order-xl-2 {
      order: 2 !important; }
    .use-bootstrap .order-xl-3 {
      order: 3 !important; }
    .use-bootstrap .order-xl-4 {
      order: 4 !important; }
    .use-bootstrap .order-xl-5 {
      order: 5 !important; }
    .use-bootstrap .order-xl-last {
      order: 6 !important; }
    .use-bootstrap .m-xl-0 {
      margin: 0 !important; }
    .use-bootstrap .m-xl-1 {
      margin: 0.25rem !important; }
    .use-bootstrap .m-xl-2 {
      margin: 0.5rem !important; }
    .use-bootstrap .m-xl-3 {
      margin: 1rem !important; }
    .use-bootstrap .m-xl-4 {
      margin: 1.5rem !important; }
    .use-bootstrap .m-xl-5 {
      margin: 3rem !important; }
    .use-bootstrap .m-xl-auto {
      margin: auto !important; }
    .use-bootstrap .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .use-bootstrap .mx-xl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important; }
    .use-bootstrap .mx-xl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .use-bootstrap .mx-xl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .use-bootstrap .mx-xl-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; }
    .use-bootstrap .mx-xl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .use-bootstrap .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .use-bootstrap .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .use-bootstrap .my-xl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important; }
    .use-bootstrap .my-xl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .use-bootstrap .my-xl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .use-bootstrap .my-xl-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; }
    .use-bootstrap .my-xl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .use-bootstrap .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .use-bootstrap .mt-xl-0 {
      margin-top: 0 !important; }
    .use-bootstrap .mt-xl-1 {
      margin-top: 0.25rem !important; }
    .use-bootstrap .mt-xl-2 {
      margin-top: 0.5rem !important; }
    .use-bootstrap .mt-xl-3 {
      margin-top: 1rem !important; }
    .use-bootstrap .mt-xl-4 {
      margin-top: 1.5rem !important; }
    .use-bootstrap .mt-xl-5 {
      margin-top: 3rem !important; }
    .use-bootstrap .mt-xl-auto {
      margin-top: auto !important; }
    .use-bootstrap .me-xl-0 {
      margin-right: 0 !important; }
    .use-bootstrap .me-xl-1 {
      margin-right: 0.25rem !important; }
    .use-bootstrap .me-xl-2 {
      margin-right: 0.5rem !important; }
    .use-bootstrap .me-xl-3 {
      margin-right: 1rem !important; }
    .use-bootstrap .me-xl-4 {
      margin-right: 1.5rem !important; }
    .use-bootstrap .me-xl-5 {
      margin-right: 3rem !important; }
    .use-bootstrap .me-xl-auto {
      margin-right: auto !important; }
    .use-bootstrap .mb-xl-0 {
      margin-bottom: 0 !important; }
    .use-bootstrap .mb-xl-1 {
      margin-bottom: 0.25rem !important; }
    .use-bootstrap .mb-xl-2 {
      margin-bottom: 0.5rem !important; }
    .use-bootstrap .mb-xl-3 {
      margin-bottom: 1rem !important; }
    .use-bootstrap .mb-xl-4 {
      margin-bottom: 1.5rem !important; }
    .use-bootstrap .mb-xl-5 {
      margin-bottom: 3rem !important; }
    .use-bootstrap .mb-xl-auto {
      margin-bottom: auto !important; }
    .use-bootstrap .ms-xl-0 {
      margin-left: 0 !important; }
    .use-bootstrap .ms-xl-1 {
      margin-left: 0.25rem !important; }
    .use-bootstrap .ms-xl-2 {
      margin-left: 0.5rem !important; }
    .use-bootstrap .ms-xl-3 {
      margin-left: 1rem !important; }
    .use-bootstrap .ms-xl-4 {
      margin-left: 1.5rem !important; }
    .use-bootstrap .ms-xl-5 {
      margin-left: 3rem !important; }
    .use-bootstrap .ms-xl-auto {
      margin-left: auto !important; }
    .use-bootstrap .p-xl-0 {
      padding: 0 !important; }
    .use-bootstrap .p-xl-1 {
      padding: 0.25rem !important; }
    .use-bootstrap .p-xl-2 {
      padding: 0.5rem !important; }
    .use-bootstrap .p-xl-3 {
      padding: 1rem !important; }
    .use-bootstrap .p-xl-4 {
      padding: 1.5rem !important; }
    .use-bootstrap .p-xl-5 {
      padding: 3rem !important; }
    .use-bootstrap .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .use-bootstrap .px-xl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
    .use-bootstrap .px-xl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .use-bootstrap .px-xl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .use-bootstrap .px-xl-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; }
    .use-bootstrap .px-xl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .use-bootstrap .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .use-bootstrap .py-xl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important; }
    .use-bootstrap .py-xl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .use-bootstrap .py-xl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .use-bootstrap .py-xl-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; }
    .use-bootstrap .py-xl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .use-bootstrap .pt-xl-0 {
      padding-top: 0 !important; }
    .use-bootstrap .pt-xl-1 {
      padding-top: 0.25rem !important; }
    .use-bootstrap .pt-xl-2 {
      padding-top: 0.5rem !important; }
    .use-bootstrap .pt-xl-3 {
      padding-top: 1rem !important; }
    .use-bootstrap .pt-xl-4 {
      padding-top: 1.5rem !important; }
    .use-bootstrap .pt-xl-5 {
      padding-top: 3rem !important; }
    .use-bootstrap .pe-xl-0 {
      padding-right: 0 !important; }
    .use-bootstrap .pe-xl-1 {
      padding-right: 0.25rem !important; }
    .use-bootstrap .pe-xl-2 {
      padding-right: 0.5rem !important; }
    .use-bootstrap .pe-xl-3 {
      padding-right: 1rem !important; }
    .use-bootstrap .pe-xl-4 {
      padding-right: 1.5rem !important; }
    .use-bootstrap .pe-xl-5 {
      padding-right: 3rem !important; }
    .use-bootstrap .pb-xl-0 {
      padding-bottom: 0 !important; }
    .use-bootstrap .pb-xl-1 {
      padding-bottom: 0.25rem !important; }
    .use-bootstrap .pb-xl-2 {
      padding-bottom: 0.5rem !important; }
    .use-bootstrap .pb-xl-3 {
      padding-bottom: 1rem !important; }
    .use-bootstrap .pb-xl-4 {
      padding-bottom: 1.5rem !important; }
    .use-bootstrap .pb-xl-5 {
      padding-bottom: 3rem !important; }
    .use-bootstrap .ps-xl-0 {
      padding-left: 0 !important; }
    .use-bootstrap .ps-xl-1 {
      padding-left: 0.25rem !important; }
    .use-bootstrap .ps-xl-2 {
      padding-left: 0.5rem !important; }
    .use-bootstrap .ps-xl-3 {
      padding-left: 1rem !important; }
    .use-bootstrap .ps-xl-4 {
      padding-left: 1.5rem !important; }
    .use-bootstrap .ps-xl-5 {
      padding-left: 3rem !important; }
    .use-bootstrap .text-xl-start {
      text-align: left !important; }
    .use-bootstrap .text-xl-end {
      text-align: right !important; }
    .use-bootstrap .text-xl-center {
      text-align: center !important; } }
  @media (min-width: 1400px) {
    .use-bootstrap .float-xxl-start {
      float: left !important; }
    .use-bootstrap .float-xxl-end {
      float: right !important; }
    .use-bootstrap .float-xxl-none {
      float: none !important; }
    .use-bootstrap .d-xxl-inline {
      display: inline !important; }
    .use-bootstrap .d-xxl-inline-block {
      display: inline-block !important; }
    .use-bootstrap .d-xxl-block {
      display: block !important; }
    .use-bootstrap .d-xxl-grid {
      display: grid !important; }
    .use-bootstrap .d-xxl-table {
      display: table !important; }
    .use-bootstrap .d-xxl-table-row {
      display: table-row !important; }
    .use-bootstrap .d-xxl-table-cell {
      display: table-cell !important; }
    .use-bootstrap .d-xxl-flex {
      display: flex !important; }
    .use-bootstrap .d-xxl-inline-flex {
      display: inline-flex !important; }
    .use-bootstrap .d-xxl-none {
      display: none !important; }
    .use-bootstrap .flex-xxl-fill {
      flex: 1 1 auto !important; }
    .use-bootstrap .flex-xxl-row {
      flex-direction: row !important; }
    .use-bootstrap .flex-xxl-column {
      flex-direction: column !important; }
    .use-bootstrap .flex-xxl-row-reverse {
      flex-direction: row-reverse !important; }
    .use-bootstrap .flex-xxl-column-reverse {
      flex-direction: column-reverse !important; }
    .use-bootstrap .flex-xxl-grow-0 {
      flex-grow: 0 !important; }
    .use-bootstrap .flex-xxl-grow-1 {
      flex-grow: 1 !important; }
    .use-bootstrap .flex-xxl-shrink-0 {
      flex-shrink: 0 !important; }
    .use-bootstrap .flex-xxl-shrink-1 {
      flex-shrink: 1 !important; }
    .use-bootstrap .flex-xxl-wrap {
      flex-wrap: wrap !important; }
    .use-bootstrap .flex-xxl-nowrap {
      flex-wrap: nowrap !important; }
    .use-bootstrap .flex-xxl-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .use-bootstrap .gap-xxl-0 {
      gap: 0 !important; }
    .use-bootstrap .gap-xxl-1 {
      gap: 0.25rem !important; }
    .use-bootstrap .gap-xxl-2 {
      gap: 0.5rem !important; }
    .use-bootstrap .gap-xxl-3 {
      gap: 1rem !important; }
    .use-bootstrap .gap-xxl-4 {
      gap: 1.5rem !important; }
    .use-bootstrap .gap-xxl-5 {
      gap: 3rem !important; }
    .use-bootstrap .justify-content-xxl-start {
      justify-content: flex-start !important; }
    .use-bootstrap .justify-content-xxl-end {
      justify-content: flex-end !important; }
    .use-bootstrap .justify-content-xxl-center {
      justify-content: center !important; }
    .use-bootstrap .justify-content-xxl-between {
      justify-content: space-between !important; }
    .use-bootstrap .justify-content-xxl-around {
      justify-content: space-around !important; }
    .use-bootstrap .justify-content-xxl-evenly {
      justify-content: space-evenly !important; }
    .use-bootstrap .align-items-xxl-start {
      align-items: flex-start !important; }
    .use-bootstrap .align-items-xxl-end {
      align-items: flex-end !important; }
    .use-bootstrap .align-items-xxl-center {
      align-items: center !important; }
    .use-bootstrap .align-items-xxl-baseline {
      align-items: baseline !important; }
    .use-bootstrap .align-items-xxl-stretch {
      align-items: stretch !important; }
    .use-bootstrap .align-content-xxl-start {
      align-content: flex-start !important; }
    .use-bootstrap .align-content-xxl-end {
      align-content: flex-end !important; }
    .use-bootstrap .align-content-xxl-center {
      align-content: center !important; }
    .use-bootstrap .align-content-xxl-between {
      align-content: space-between !important; }
    .use-bootstrap .align-content-xxl-around {
      align-content: space-around !important; }
    .use-bootstrap .align-content-xxl-stretch {
      align-content: stretch !important; }
    .use-bootstrap .align-self-xxl-auto {
      align-self: auto !important; }
    .use-bootstrap .align-self-xxl-start {
      align-self: flex-start !important; }
    .use-bootstrap .align-self-xxl-end {
      align-self: flex-end !important; }
    .use-bootstrap .align-self-xxl-center {
      align-self: center !important; }
    .use-bootstrap .align-self-xxl-baseline {
      align-self: baseline !important; }
    .use-bootstrap .align-self-xxl-stretch {
      align-self: stretch !important; }
    .use-bootstrap .order-xxl-first {
      order: -1 !important; }
    .use-bootstrap .order-xxl-0 {
      order: 0 !important; }
    .use-bootstrap .order-xxl-1 {
      order: 1 !important; }
    .use-bootstrap .order-xxl-2 {
      order: 2 !important; }
    .use-bootstrap .order-xxl-3 {
      order: 3 !important; }
    .use-bootstrap .order-xxl-4 {
      order: 4 !important; }
    .use-bootstrap .order-xxl-5 {
      order: 5 !important; }
    .use-bootstrap .order-xxl-last {
      order: 6 !important; }
    .use-bootstrap .m-xxl-0 {
      margin: 0 !important; }
    .use-bootstrap .m-xxl-1 {
      margin: 0.25rem !important; }
    .use-bootstrap .m-xxl-2 {
      margin: 0.5rem !important; }
    .use-bootstrap .m-xxl-3 {
      margin: 1rem !important; }
    .use-bootstrap .m-xxl-4 {
      margin: 1.5rem !important; }
    .use-bootstrap .m-xxl-5 {
      margin: 3rem !important; }
    .use-bootstrap .m-xxl-auto {
      margin: auto !important; }
    .use-bootstrap .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important; }
    .use-bootstrap .mx-xxl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important; }
    .use-bootstrap .mx-xxl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important; }
    .use-bootstrap .mx-xxl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important; }
    .use-bootstrap .mx-xxl-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important; }
    .use-bootstrap .mx-xxl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important; }
    .use-bootstrap .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important; }
    .use-bootstrap .my-xxl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important; }
    .use-bootstrap .my-xxl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important; }
    .use-bootstrap .my-xxl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important; }
    .use-bootstrap .my-xxl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; }
    .use-bootstrap .my-xxl-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important; }
    .use-bootstrap .my-xxl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important; }
    .use-bootstrap .my-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important; }
    .use-bootstrap .mt-xxl-0 {
      margin-top: 0 !important; }
    .use-bootstrap .mt-xxl-1 {
      margin-top: 0.25rem !important; }
    .use-bootstrap .mt-xxl-2 {
      margin-top: 0.5rem !important; }
    .use-bootstrap .mt-xxl-3 {
      margin-top: 1rem !important; }
    .use-bootstrap .mt-xxl-4 {
      margin-top: 1.5rem !important; }
    .use-bootstrap .mt-xxl-5 {
      margin-top: 3rem !important; }
    .use-bootstrap .mt-xxl-auto {
      margin-top: auto !important; }
    .use-bootstrap .me-xxl-0 {
      margin-right: 0 !important; }
    .use-bootstrap .me-xxl-1 {
      margin-right: 0.25rem !important; }
    .use-bootstrap .me-xxl-2 {
      margin-right: 0.5rem !important; }
    .use-bootstrap .me-xxl-3 {
      margin-right: 1rem !important; }
    .use-bootstrap .me-xxl-4 {
      margin-right: 1.5rem !important; }
    .use-bootstrap .me-xxl-5 {
      margin-right: 3rem !important; }
    .use-bootstrap .me-xxl-auto {
      margin-right: auto !important; }
    .use-bootstrap .mb-xxl-0 {
      margin-bottom: 0 !important; }
    .use-bootstrap .mb-xxl-1 {
      margin-bottom: 0.25rem !important; }
    .use-bootstrap .mb-xxl-2 {
      margin-bottom: 0.5rem !important; }
    .use-bootstrap .mb-xxl-3 {
      margin-bottom: 1rem !important; }
    .use-bootstrap .mb-xxl-4 {
      margin-bottom: 1.5rem !important; }
    .use-bootstrap .mb-xxl-5 {
      margin-bottom: 3rem !important; }
    .use-bootstrap .mb-xxl-auto {
      margin-bottom: auto !important; }
    .use-bootstrap .ms-xxl-0 {
      margin-left: 0 !important; }
    .use-bootstrap .ms-xxl-1 {
      margin-left: 0.25rem !important; }
    .use-bootstrap .ms-xxl-2 {
      margin-left: 0.5rem !important; }
    .use-bootstrap .ms-xxl-3 {
      margin-left: 1rem !important; }
    .use-bootstrap .ms-xxl-4 {
      margin-left: 1.5rem !important; }
    .use-bootstrap .ms-xxl-5 {
      margin-left: 3rem !important; }
    .use-bootstrap .ms-xxl-auto {
      margin-left: auto !important; }
    .use-bootstrap .p-xxl-0 {
      padding: 0 !important; }
    .use-bootstrap .p-xxl-1 {
      padding: 0.25rem !important; }
    .use-bootstrap .p-xxl-2 {
      padding: 0.5rem !important; }
    .use-bootstrap .p-xxl-3 {
      padding: 1rem !important; }
    .use-bootstrap .p-xxl-4 {
      padding: 1.5rem !important; }
    .use-bootstrap .p-xxl-5 {
      padding: 3rem !important; }
    .use-bootstrap .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important; }
    .use-bootstrap .px-xxl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important; }
    .use-bootstrap .px-xxl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important; }
    .use-bootstrap .px-xxl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important; }
    .use-bootstrap .px-xxl-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important; }
    .use-bootstrap .px-xxl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important; }
    .use-bootstrap .py-xxl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important; }
    .use-bootstrap .py-xxl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important; }
    .use-bootstrap .py-xxl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important; }
    .use-bootstrap .py-xxl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important; }
    .use-bootstrap .py-xxl-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important; }
    .use-bootstrap .py-xxl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important; }
    .use-bootstrap .pt-xxl-0 {
      padding-top: 0 !important; }
    .use-bootstrap .pt-xxl-1 {
      padding-top: 0.25rem !important; }
    .use-bootstrap .pt-xxl-2 {
      padding-top: 0.5rem !important; }
    .use-bootstrap .pt-xxl-3 {
      padding-top: 1rem !important; }
    .use-bootstrap .pt-xxl-4 {
      padding-top: 1.5rem !important; }
    .use-bootstrap .pt-xxl-5 {
      padding-top: 3rem !important; }
    .use-bootstrap .pe-xxl-0 {
      padding-right: 0 !important; }
    .use-bootstrap .pe-xxl-1 {
      padding-right: 0.25rem !important; }
    .use-bootstrap .pe-xxl-2 {
      padding-right: 0.5rem !important; }
    .use-bootstrap .pe-xxl-3 {
      padding-right: 1rem !important; }
    .use-bootstrap .pe-xxl-4 {
      padding-right: 1.5rem !important; }
    .use-bootstrap .pe-xxl-5 {
      padding-right: 3rem !important; }
    .use-bootstrap .pb-xxl-0 {
      padding-bottom: 0 !important; }
    .use-bootstrap .pb-xxl-1 {
      padding-bottom: 0.25rem !important; }
    .use-bootstrap .pb-xxl-2 {
      padding-bottom: 0.5rem !important; }
    .use-bootstrap .pb-xxl-3 {
      padding-bottom: 1rem !important; }
    .use-bootstrap .pb-xxl-4 {
      padding-bottom: 1.5rem !important; }
    .use-bootstrap .pb-xxl-5 {
      padding-bottom: 3rem !important; }
    .use-bootstrap .ps-xxl-0 {
      padding-left: 0 !important; }
    .use-bootstrap .ps-xxl-1 {
      padding-left: 0.25rem !important; }
    .use-bootstrap .ps-xxl-2 {
      padding-left: 0.5rem !important; }
    .use-bootstrap .ps-xxl-3 {
      padding-left: 1rem !important; }
    .use-bootstrap .ps-xxl-4 {
      padding-left: 1.5rem !important; }
    .use-bootstrap .ps-xxl-5 {
      padding-left: 3rem !important; }
    .use-bootstrap .text-xxl-start {
      text-align: left !important; }
    .use-bootstrap .text-xxl-end {
      text-align: right !important; }
    .use-bootstrap .text-xxl-center {
      text-align: center !important; } }
  @media (min-width: 1200px) {
    .use-bootstrap .fs-1 {
      font-size: 2.5rem !important; }
    .use-bootstrap .fs-2 {
      font-size: 2rem !important; }
    .use-bootstrap .fs-3 {
      font-size: 1.75rem !important; }
    .use-bootstrap .fs-4 {
      font-size: 1.5rem !important; } }
  @media print {
    .use-bootstrap .d-print-inline {
      display: inline !important; }
    .use-bootstrap .d-print-inline-block {
      display: inline-block !important; }
    .use-bootstrap .d-print-block {
      display: block !important; }
    .use-bootstrap .d-print-grid {
      display: grid !important; }
    .use-bootstrap .d-print-table {
      display: table !important; }
    .use-bootstrap .d-print-table-row {
      display: table-row !important; }
    .use-bootstrap .d-print-table-cell {
      display: table-cell !important; }
    .use-bootstrap .d-print-flex {
      display: flex !important; }
    .use-bootstrap .d-print-inline-flex {
      display: inline-flex !important; }
    .use-bootstrap .d-print-none {
      display: none !important; } }

.SButton-default {
  padding: 0.8rem;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: transparent;
  border-radius: 2rem;
  min-width: 3rem;
  max-width: fit-content;
  border-color: grey;
  border: 0.15rem solid grey;
  color: #575757;
  margin: 1.5rem; }

.SButton-default:hover {
  background-color: grey;
  border-color: grey;
  color: white; }

.SButton-lightBlue {
  background-color: #68a8d3;
  border-color: #68a8d3;
  color: white; }

.SButton-lightBlue:hover {
  background-color: #68a8d3;
  border-color: #68a8d3;
  color: white; }

.SButton-darkBlue {
  background-color: #68a8d3;
  border-color: #68a8d3;
  color: white; }

.SButton-darkBlue:hover {
  background-color: #68a8d3;
  border-color: #68a8d3;
  color: white; }

.SButton-stemGreen {
  padding: 0.8rem;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: white;
  color: #2d921f;
  border-radius: 2rem;
  min-width: 3rem;
  max-width: fit-content;
  border-color: #36ac26;
  border: 0.15rem solid #36ac26;
  margin: 1.5rem; }

.SButton-stemGreen:hover {
  background-color: #36ac26;
  border-color: #36ac26;
  color: white; }

.SButton-capillary {
  padding: 0.8rem;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: white;
  color: #610752;
  border-radius: 2rem;
  min-width: 3rem;
  max-width: fit-content;
  border-color: #6e075d;
  border: 0.15rem solid #6e075d;
  margin: 1.5rem; }

.SButton-capillary:hover {
  background-color: #6e075d;
  border-color: #6e075d;
  color: white; }

.SButton-bubbleGum {
  padding: 0.8rem;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: white;
  color: #bb25a2;
  border-radius: 2rem;
  min-width: 3rem;
  max-width: fit-content;
  border-color: #bb25a2;
  border: 0.15rem solid #bb25a2;
  margin: 1.5rem; }

.SButton-bubbleGum:hover {
  background-color: #bb25a2;
  border-color: #bb25a2;
  color: white; }

.SButton-forestGreen {
  padding: 0.8rem;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: white;
  color: #076e18;
  border-radius: 2rem;
  min-width: 3rem;
  max-width: fit-content;
  border-color: #076e18;
  border: 0.15rem solid #076e18;
  margin: 1.5rem; }

.SButton-forestGreen:hover {
  background-color: #076e18;
  border-color: #076e18;
  color: white; }

.SButton-prettyPink {
  padding: 0.8rem;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: white;
  color: #cc539e;
  border-radius: 2rem;
  min-width: 3rem;
  max-width: fit-content;
  border-color: #e265b2;
  border: 0.15rem solid #e265b2;
  margin: 1.5rem; }

.SButton-prettyPink:hover {
  background-color: #e265b2;
  border-color: #e265b2;
  color: white; }

.SButton-bullRed {
  padding: 0.8rem;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: white;
  color: #a51515;
  border-radius: 2rem;
  min-width: 3rem;
  max-width: fit-content;
  border-color: #bb1f1f;
  border: 0.15rem solid #bb1f1f;
  margin: 1.5rem; }

.SButton-bullRed:hover {
  background-color: #bb1f1f;
  border-color: #bb1f1f;
  color: white; }

.SContainer {
  width: 100%;
  min-height: 100vh;
  max-height: 100%; }
  .SContainer-start {
    width: 100%;
    height: 100%; }
  .SContainer-toBe {
    min-height: 100vh;
    max-height: 100%;
    display: flex; }
  .SContainer-home {
    overflow: auto;
    background-color: #e5e5f7;
    opacity: 0.8;
    background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #e5e5f7 22px), repeating-linear-gradient(#444cf755, #444cf7); }

.Simg {
  width: 20rem;
  display: inline-block; }

.SRow {
  text-align: center;
  -ms-flex-order: 1;
  margin: 3rem;
  margin-left: 4rem;
  display: block;
  overflow: auto; }

.SStud {
  display: inline-block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding: 3rem; }

.SColumn {
  display: inline-block;
  text-align: center; }

.SCodeBlock {
  background-color: #eeeeee;
  max-width: 42rem;
  min-width: fit-content;
  margin: 4rem;
  padding: 4rem;
  margin-left: auto;
  margin-right: auto;
  font-family: "Courier New";
  font-weight: 600;
  font-size: 1.2rem; }

.STitle {
  max-width: 33rem;
  color: #464646;
  margin-bottom: 2rem;
  font-size: 1.3rem; }

.SHeader {
  width: 100%;
  height: 5rem;
  background-color: orange;
  display: block;
  z-index: 2;
  overflow: auto; }

.SSidebar {
  flex-grow: 0;
  flex-shrink: 0;
  background-color: #e0e0e0;
  min-height: 100vh;
  max-height: 100%;
  width: 18rem;
  flex-direction: column;
  display: flex; }

.ColumnContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1; }

.SColumn {
  display: inline-block;
  width: fit-content;
  vertical-align: top; }

.SLabel {
  display: inline-block;
  width: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.findAColor {
  color: #9b9b9b; }

.SCard {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 3rem;
  margin-right: 3rem;
  display: inline-block;
  border: solid grey 0.1rem;
  width: 27.5rem;
  height: 40rem;
  background-color: #d8d8d8; }
  .SCard-pocketKnife {
    background-color: white;
    border: solid silver 0.01rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 3rem;
    margin-right: 3rem;
    display: inline-block;
    transition: all 0.34s;
    vertical-align: top;
    width: 27.5rem;
    height: 40rem;
    font-size: 1.4rem; }
    .SCard-pocketKnife-bottleOpener {
      width: 25rem;
      margin: auto;
      text-align: left; }
  .SCard-pocketKnife:hover {
    transform: translateY(-1.2rem); }
  .SCard-bookShelf {
    width: 27.5rem;
    height: 40rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: 2rem;
    margin-left: 2rem;
    display: inline-block;
    vertical-align: top;
    font-size: 1.8rem; }
    .SCard-bookShelf-partition {
      text-align: left; }
  .SCard-socialStar {
    margin-right: auto;
    margin-left: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 50rem;
    min-height: 14rem;
    display: block;
    border: solid silver 0.01rem;
    box-shadow: 0.1rem 0.1rem 0.3rem 0.3rem #eeeeee;
    text-align: left;
    font-size: 1.55rem; }
    .SCard-socialStar-header {
      margin-left: 2rem;
      margin-top: 1.5rem;
      margin-bottom: 1.2rem; }
      .SCard-socialStar-header-profilePicture {
        width: 4rem;
        height: 4rem;
        border-radius: 4rem;
        display: inline-block;
        vertical-align: middle; }
      .SCard-socialStar-header-title {
        display: inline-block;
        margin-left: 1rem;
        width: fit-content;
        vertical-align: middle; }
    .SCard-socialStar-postInfo {
      margin-left: 2rem;
      margin-right: 2rem; }
    .SCard-socialStar-postMoves {
      text-align: center;
      transform-origin: bottom;
      margin-bottom: 1.5rem;
      margin-top: 0.4rem; }
      .SCard-socialStar-postMoves-moves {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.7rem;
        margin-left: 2rem;
        margin-right: 2rem; }

.postmoves-sizer {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.3rem; }
  .postmoves-sizer svg {
    display: block; }

.gavHeaderTitles {
  margin: 1.2rem;
  padding: 1rem;
  margin-left: 1rem;
  text-decoration: none;
  color: #0505e2;
  font-size: 2.3rem;
  font-family: "Courier New";
  font-weight: 600;
  overflow: auto;
  margin-top: 5rem; }

.marginleft {
  padding-left: 4rem; }

.marginTop {
  margin-top: 0.8rem;
  margin-left: 1.5rem; }
